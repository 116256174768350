/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.user-modal .modal-content {
  border: 0 !important;
  padding: 10px;
}

.user-modal__close__icon {
  position: relative;
  top: -55px;
  right: -27px;
  background: transparent;
}

.user-search__icon {
  background: #fff;
  display: flex;
  align-items: center;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.user-search__input {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.user__text__ellipsis {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user__name__text__ellipsis {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organisation__user-tabs {
  border-bottom: 0 !important;
  display: flex;
  justify-content: center;
}

.organisation__user-tabs a {
  height: 56px;
  width: 150px;
  border: 1px solid #db9cc1 !important;
  font-size: 16px !important;
  font-family: 'sofia-pro-light' !important;
  color: #4d5d67 !important;
  text-align: center;
  background-color: transparent !important;
  border-radius: 5px !important;
}

.organisation__user-tabs a.active {
  color: #fff !important;
  text-align: center;
  background-color: #e10177 !important;
  border-radius: 5px !important;
  font-family: 'sofia-pro-light' !important;
}

.organisation__user .tab-content {
  // background-color: #f8f9fa !important;
}

.organisation__user-textarea-input {
  outline: none !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  background-color: #f8f9fa !important;
  font-family: 'sofia-pro-light' !important;
}

.invite__employee-upload-style > input {
  display: none;
}

.invite__employee-upload-style img {
  width: 80px;
  cursor: pointer;
}

.invite__employee-upload-style {
  border: 2px dashed #b5c3cc;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table td {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e7edf2;
}

.table tbody tr:last-child td {
  border-bottom: none;
}

.table_headings {
  font-size: 14px;
  font-weight: 500;
  color: #4d5d67;
  line-height: 14px;
}

.invite_paragraph {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.btn_cancel_invite {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.form-control-wrapper {
  position: relative;
}

.date-icon {
  position: absolute;
  right: 0px; // You can adjust the right value to change the position of the icon horizontally
  transform: translateY(-25%);
}

.title-input {
  width: 95% !important;
  @media (max-width: 767px) {
    width: 48% !important;
  }
}

.title-label {
  font-size: 14px !important;
  @media (max-width: 767px) {
    font-size: 13px !important;
  }
  @media (max-width: 360px) {
    font-size: 11px !important;
  }
}

.milestone-row {
  position: relative !important;
}

.milestone-title {
  position: absolute !important;
  left: 10% !important;
  @media (max-width: 767px) {
    left: 10% !important;
    font-size: 14px !important;
  }
}

.milestone-date {
  position: absolute !important;
  left: 55% !important;
  @media (max-width: 575px) {
    left: 55% !important;
    font-size: 14px !important;
  }
}

.milestone-edit {
  position: absolute !important;
  left: 95% !important;
  @media (max-width: 485px) {
    left: 94% !important;
  }
}
.modal-dialog {
  @media (min-width: 576px) {
    max-width: 950px !important;
    margin: 1.75rem auto !important;
  }
}

.modal-inner {
  @media (min-width: 767px) and (max-width: 1024px) {
    margin-left: 10.75rem !important;
    margin-right: 10.75rem !important;
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  @media (min-width: 1024px) {
    margin-left: 16.75rem !important;
    margin-right: 16.75rem !important;
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  // @media (min-width: 576px) {
  //   margin-left: 4.75rem !important;
  //   margin-right: 4.75rem !important;
  //   margin-top: 2.75rem !important;
  //   margin-bottom: 2.75rem !important;
  // }
  @media (min-width: 360px) and (max-width: 400px) {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
}

.modal-invite-inner {
  @media (min-width: 576px) {
    margin-top: 3rem;
    margin-bottom: 6rem;
    margin-right: 12rem;
    margin-left: 12rem;
  }
}

.nav-link {
  display: block !important;
  padding: 1rem 1rem !important;
}

.nav-link-csv {
  border-left: none !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.nav-link-mail {
  border-right: none !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

// .nav-tabs .nav-link {
//   margin-bottom: -1px;
//   border: 1px solid transparent;
//   border-top-left-radius: 0 !important;
//   border-top-right-radius: 0 !important;
// }

.table-edit {
  @media (min-width: 576px) {
    margin-right: 0.5rem;
  }
  @media (min-width: 666px) {
    margin-right: 1.5rem;
  }
}

/* Style the input text */
.ant-picker .ant-picker-input > input {
  /* Add your custom styles here */
  margin-top: 10%;
}

.left {
  height: 56px;
  width: 166px;
  border: 1px solid #db9cc1 !important;
  font-size: 16px !important;
  font-family: 'sofia-pro-light' !important;
  color: #4d5d67 !important;
  text-align: center !important;
  background-color: transparent !important;
  border-top-left-radius: 5px; /* Rounded corners on the top-left */
  border-bottom-left-radius: 5px; /* Rounded corners on the bottom-left */
  @media (max-width: 767px) {
    height: 45px;
    width: 130px;
    font-size: 14px !important;
  }
}

.left-active {
  height: 56px;
  width: 166px;
  color: #fff !important;
  text-align: center !important;
  background-color: #e10177 !important;
  // border-radius: 5px !important;
  font-family: 'sofia-pro-light' !important;
  border-top-left-radius: 5px; /* Rounded corners on the top-left */
  border-bottom-left-radius: 5px; /* Rounded corners on the bottom-left */
  @media (max-width: 767px) {
    height: 45px;
    width: 130px;
    font-size: 14px !important;
  }
}

.right {
  height: 56px;
  width: 166px;
  border: 1px solid #db9cc1 !important;
  font-size: 16px !important;
  font-family: 'sofia-pro-light' !important;
  color: #4d5d67 !important;
  text-align: center !important;
  background-color: transparent !important;
  border-top-right-radius: 5px; /* Rounded corners on the top-right */
  border-bottom-right-radius: 5px; /* Rounded corners on the bottom-right */
  @media (max-width: 767px) {
    height: 45px;
    width: 130px;
    font-size: 14px !important;
  }
}

.right-active {
  height: 56px;
  width: 166px;
  color: #fff !important;
  text-align: center !important;
  background-color: #e10177 !important;
  // border-radius: 5px !important;
  font-family: 'sofia-pro-light' !important;
  border-top-right-radius: 5px; /* Rounded corners on the top-right */
  border-bottom-right-radius: 5px; /* Rounded corners on the bottom-right */
  @media (max-width: 767px) {
    height: 45px;
    width: 130px;
    font-size: 14px !important;
  }
}

.card-headings {
  height: 20px;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #4d5d67;
  margin-right: 50px;
  padding-bottom: 50px;
}
.card-title {
  top: 268px;
  left: 132px;
  font-size: 14px !important;
  letter-spacing: -0.005em;
  text-align: left;
  color: #101317;
  @media (max-width: 576px) {
  }
  @media (min-width: 320px) and (max-width: 360px) {
  }
}

.card-user-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-user {
  width: 340px;
  height: 280px;
  top: 20px;
  left: 15px;
}
.card-contact {
  width: 341px;
  height: 95px;
  top: 20px;
  left: 35px;
}
.input-group-contact {
  width: 295px !important;
  height: 44px !important;
}
.add-contact-mobile {
  width: 50px;
  height: 50px;
}
@media (min-width: 768px) {
  .contact-body-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

.contact-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
  @media (min-width: 300px) and (max-width: 400px) {
    margin-top: 20px;
    margin-left: 20px;
  }
}
.contact-card-icons {
  gap: 15px;
  margin-left: auto;
  @media (min-width: 300px) and (max-width: 400px) {
    margin-right: 20px;
    margin-top: 20px;
  }
}
.footer-btns {
  margin-top: 155px;
  gap: 15px;
  // @media (min-width: 320px) and (max-width: 360px) {
  //   margin-top: 188px;
  // }
  // @media (max-width: 576px) {
  //   margin-top: 188px;
  // }
}
.user-event {
  max-width: 59px;
}
.access-status {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.upcoming-events {
  display: flex;
  flex-direction: row;
}
.user-profile {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.user-owner {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 2px;
}
.cards-area {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .search-input-mobile {
    width: 216px;
    height: 44px;
  }
}
@media (max-width: 767px) {
  .mobile-background {
    background: #f8f9fa;
    width: 410px;
    height: 80rem;
  }
}
@media (max-width: 767px) {
  .contact-background {
    background: #f8f9fa;
    width: 410px;
    height: 850px;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    margin-right: 46px;
  }
}
@media (max-width: 767px) {
  .table-responsive {
    margin-right: 5px;
  }
}
.contact-heading {
  width: 269px;
  height: 32px;
  top: 200px;
  left: 338px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  @media (max-width: 767px) {
    margin-left: 20px;
  }
}

.contact-modal-input {
  width: 160px;
  height: 60px;
  top: 303px;
  background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #dbdcdf, #dbdcdf);

  border-radius: 5px;
  border: 1px solid #dbdcdf;

  @media (max-width: 767px) {
    width: 110px;
    height: 40px;
  }
}
.contact-modal-body {
  margin-left: 50px;
  @media (max-width: 767px) {
    margin-left: 2px;
  }
}
.contact-modal {
  width: 365px;
  height: 60px;
  top: 28px;
  border-radius: 5px;
  border: 1px solid #dbdcdf;
  background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #dbdcdf, #dbdcdf);
  @media (max-width: 767px) {
    width: 233px;
  }
}
.modal-buttons {
  width: 500px;
  height: Hug (60px);
  top: 608px;
  left: 624px;
  color: #ffffff;
  padding: 18px 26px 18px 26px;
  border-radius: 5px;
  gap: 10px;
}
.btn-cancel {
  background: linear-gradient(0deg, #b5c3cc, #b5c3cc), linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #b5c3cc;
}
.btn-add {
  background: #e10177;
  border: 1px solid #e10177;
  margin-left: 35px;
}
.modal-btns {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  gap: 115px;
}
.contactss-modal .modal-content {
  width: 540px;
  height: 550px;
  top: 80px;
  left: 180px;
  border-radius: 10px;
  @media (max-width: 767px) {
    width: 300px;
    height: 500px;
    left: 50px;
    top: 80px;
    border-radius: 10px;
  }
}
.contact-name {
  display: flex;
  flex-direction: row;
  gap: 45px;
  @media (max-width: 767px) {
    gap: 10px;
  }
}
.trash-btn {
  padding: 6px 10px 6px 10px;
  border-radius: 5px;
  border: none;
  outline-width: 0;
  background: none;

  color: #4d5d67;
  transition: color 0.3s;
}

.trash-btn:hover {
  color: #c44440;
  background: #f8f9fa;
}
.cancel-btn {
  background: transparent;
  top: 7px;
  right: 20px;
}
.btn-cancel-footer {
  border: 1px solid #b5c3cc !important;
  color: #b5c3cc !important;
  width: 118px;
}
.btn-add-footer {
  width: 118px;
  margin-right: 40px;
}
.footer-btns {
  margin-left: 15px;
  // margin-right: 1px;
  @media (max-width: 767px) {
    margin-left: 15px;
    margin-right: 18px;
  }
}
