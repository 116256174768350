/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.transaction__details-tabs {
  border-bottom: 0 !important;
}

.transaction__details-tabs a {
  width: 200px;
  border: 0 !important;
  font-size: 24px !important;
  font-family: 'sofia-pro-light' !important;
  color: #8597a0 !important;
  text-align: center;
}

.transaction__details-tabs a.active {
  color: #101317 !important;
  background-color: transparent !important;
}

.transaction__details-status-dot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 100%;
}

.transaction-location__container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.transaction-location__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.transaction-location__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.transaction-location__container:hover input ~ .transaction-location__checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.transaction-location__container input:checked ~ .transaction-location__checkmark {
  background-color: #e10177;
}

/* Create the transaction-location__checkmark/indicator (hidden when not checked) */
.transaction-location__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the transaction-location__checkmark when checked */
.transaction-location__container input:checked ~ .transaction-location__checkmark:after {
  display: block;
}

/* Style the transaction-location__checkmark/indicator */
.transaction-location__container .transaction-location__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.merchant-store__location__modal .modal-content {
  border: 0 !important;
}

.merchant-store-modal__close__icon {
  position: relative;
  top: -45px;
  left: 17px;
  background: transparent;
}
