/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.employee-modal .modal-content {
  border: 0 !important;
  padding: 10px;
}

.employee-modal__close__icon {
  position: relative;
  top: -55px;
  right: -27px;
  background: transparent;
}

.employee-search__icon {
  background: #fff;
  display: flex;
  align-items: center;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.employee-search_icon {
  background: #fff;
  display: flex;
  align-items: center;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  @media (max-width: 576px) {
    height: 48px;
  }
}

.employee-search__input {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
  @media (max-width: 767px) {
    height: 44px;
    width: 145px !important;
    background: gray;
  }
}

.employee__text__ellipsis {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee__name__text__ellipsis {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
