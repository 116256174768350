/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.dashboard-donate__topcharities {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484849;
}
.dashboard-donate_card {
  height: 410px;
  box-shadow: 0 8px 45px -12px rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(181, 195, 204, 0.5);
  background-color: #ffffff;
}
.dashboard-donate__image {
  object-fit: contain;
}
.dashboard-button__donatechar {
  height: 50px;
  border-radius: 10px;
  background-color: #e10177;
}
.dashboard-text_donate {
  width: 55px;
  height: 20px;
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.dashboard-text__We-are-an-Australian {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #101317;
}
.dahboard-text_Donate-thanks-to-Bey {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-text__Small-gestures-like {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.dashboard-donate-button_cancelicon {
  position: relative;
  top: -99px;
  left: 238px;
  background: none;
  background-color: rgba(16, 19, 23, 0);
}
.dashboard-donate-background_popup {
  background-color: rgba(16, 19, 23, 0.03);

  backdrop-filter: blur(1px);
}
.donate-proceed_width {
  height: 385px;
}
.donate-donatemorewidth {
  height: 420px;
}
.dashboard-text_Available-Thanks-Bal {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.dashboard-card__activequntity {
  width: 56px;
  height: 44px;
  border-radius: 5px;
  background-color: #e10177;
}
.dashboard-card__nonactivequntity {
  width: 56px;
  height: 44px;
  border-radius: 5px;
  border: solid 1px #ffe1f4;
  background-color: #fff2fa;
}

.dashboard-text_number {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.dashboard-text_inactive {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.dashboard-text__Total-payable-amount {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.dashboard-text_Proceed {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.dashboard-button_Proceed {
  width: 200px;
  height: 44px;
  border-radius: 5px;
  background-color: #e10177;
  align-items: center;
}
.dashboard-text__Select-your-payment {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-text__Pay-using-your-credit {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.dashboard-text__Your-saved-credit-an {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
}
.dashboard-text_VISA {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ababab;
}
.dashboard-card_edit {
  width: 57px;
  height: 34px;
  margin: 16px 8px 24px 122px;
  padding: 8px 16px;
  border-radius: 5px;
  border: solid 1px #ead6e2;
  background-color: #ffffff;
}
.dashboard-donate-text_edit {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.dashboard-text_addpayment {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
}
.dashboard-donate-radio__Iconsradiounselected {
  width: 18px;
  height: 18px;

  object-fit: contain;
}
.dashboard-donate__Add-a-Debit-or-credi {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.donate-payment-options-radio__item {
  border-radius: 11px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: ' ';
  display: block;
  background: #e10177;
}
.donate-button__donate {
  width: 200px;
  height: 44px;
  border-radius: 5px;
  background-color: #e10177;
}
.donate-text__donate {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.donate-button__canceliconpayment {
  position: relative;
  top: -45px;
  left: 455px;
  background-color: rgba(16, 19, 23, 0);
}
.donate-text_donationsuccessful {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.donate-text_Giving-is-not-about {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.doanate-button_donatemore {
  width: 160px;
  height: 44px;
  border-radius: 5px;
  background-color: #e10177;
}
.donate-text__donatemore {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.donate-button__gohome {
  width: 160px;
  height: 44px;
  border-radius: 5px;
  border: solid 1px #ffe1f4;
  background-color: #fff2fa;
}
.donate-text__gohome {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.donate-button__canceliconsucees {
  position: relative;
  top: -43px;
  left: 455px;
  background-color: rgba(16, 19, 23, 0);
}
.doante_successful {
  height: 400px;
}
@media only screen and(min-width:320px) and(max-width:359px) {
  .doanate-button_donatemore {
    width: 120px;
  }
  .donate-button__gohome {
    width: 120px;
  }
  .donate-button__canceliconsucees {
    position: relative;
    left: 255px;
  }
  .dashboard-donate-button_cancelicon {
    position: relative;
    top: -130px;
    left: 140px;
  }
  .donate-button__canceliconpayment {
    positon: relative;
    top: -45px;
    left: 255px;
  }
}

@media only screen and(min-width:360px) and(max-width:369px) {
  .doanate-button_donatemore {
    width: 120px;
  }
  .donate-button__gohome {
    width: 120px;
  }
  .donate-button__canceliconsucees {
    position: relative;
    left: 300px;
  }
  .dashboard-donate-button_cancelicon {
    position: relative;
    top: -130px;
    left: 160px;
  }
  .donate-button__canceliconpayment {
    positon: relative;
    top: -45px;
    left: 300px;
  }
}

@media only screen and(min-width:370px) and(max-width:400px) {
  .doanate-button_donatemore {
    width: 120px;
  }
  .donate-button__gohome {
    width: 120px;
  }
  .donate-button__canceliconsucees {
    position: relative;
    left: 315px;
  }
  .dashboard-donate-button_cancelicon {
    position: relative;
    top: -130px;
    left: 170px;
  }
  .donate-button__canceliconpayment {
    positon: relative;
    top: -45px;
    left: 315px;
  }
}
@media only screen and(min-width:400px) and(max-width:420px) {
  .doanate-button_donatemore {
    width: 120px;
  }
  .donate-button__gohome {
    width: 120px;
  }
  .donate-button__canceliconsucees {
    position: relative;
    left: 350px;
  }
  .dashboard-donate-button_cancelicon {
    position: relative;
    top: -95px;
    left: 190px;
  }
  .donate-button__canceliconpayment {
    positon: relative;
    top: -45px;
    left: 350px;
  }
}
