#dropdown-basic {
  background: #ffe1f4;
  font-size: 0.9rem;

  &:focus {
    box-shadow: unset;
  }

  &::after {
    content: unset !important;
  }
}

.dropdown-menu {
  .notification-heading {
    padding: 0.25rem 0.7rem;
    font-weight: bold;
    font-size: 14px;
  }
}

.toast-main {
  box-shadow: none !important;
  margin: 0 !important;
}
.toast-content {
  align-items: flex-start;
  flex-basis: 300px;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
  border-radius: 0.25rem;
}
.custom-tooltip {
  opacity: 1 !important;
  &_250 {
    width: 250px !important;
  }
  & > .tooltip-inner {
    max-width: 350px !important;
    background-color: #ffe1f4 !important;
    color: black;
    text-align: start;
  }
}

.custom-tooltip-profile {
  opacity: 1 !important;
  width: 210px;
  box-shadow: 0 8px 35px -12px rgba(0,0,0,.4);
  background: #fff !important;
  border: 0 !important;
  margin: 12px !important;
  & > .tooltip-inner {
    background-color: white !important;
    color: black;
    text-align: start;

  }
}

.audio-list-class {
  background: #ffe1f4;
  background-image: url('../../assets/images/redeem_thanks/image/Voice-Message.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: 0.5px solid #c1c1c1;
  border-radius: 5px;
  &_100 {
    width: 100px;
    height: 100px;
  }
  &_240 {
    width: 240px;
    height: 240px;
  }
  .mike {
    margin-top: 70%;
  }
  .play {
    width: 25px;
    height: 25px;
    margin-left: 13px;
    cursor: pointer;
  }
}

.arrow::before, .arrow::before {
  display: none;
}

.close-tooltip {
  top: 10px;
  right: 10px;
}