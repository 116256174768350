/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.invite__employees-tabs {
  border-bottom: 0 !important;
  display: flex;
  justify-content: center;
}

.invite__employees-tabs a {
  width: 250px;
  border: 1px solid #db9cc1 !important;
  font-size: 16px !important;
  font-family: 'sofia-pro-light' !important;
  color: #4d5d67 !important;
  text-align: center;
  background-color: transparent !important;
  border-radius: 5px !important;
}

.invite__employees-tabs a.active {
  color: #fff !important;
  text-align: center;
  background-color: #e10177 !important;
  border-radius: 5px !important;
  font-family: 'sofia-pro-semi-bold' !important;
}

.invite__employees .tab-content {
  // background-color: #f8f9fa !important;
}

.invite__employees-textarea-input {
  outline: none !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  background-color: #f8f9fa !important;
  font-family: 'sofia-pro-light' !important;
}

.invite__employee-upload-style > input {
  display: none;
}

.invite__employee-upload-style img {
  width: 80px;
  cursor: pointer;
}

.invite__employee-upload-style {
  border: 2px dashed #b5c3cc;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
