/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.business-details__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.business-details-image-name__text__ellipsis {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* The merchant-business__container */
.merchant-business__container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.merchant-business__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.merchant-business__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.merchant-business__container:hover input ~ .merchant-business__checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.merchant-business__container input:checked ~ .merchant-business__checkmark {
  background-color: #e10177;
}

/* Create the merchant-business__checkmark/indicator (hidden when not checked) */
.merchant-business__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the merchant-business__checkmark when checked */
.merchant-business__container input:checked ~ .merchant-business__checkmark:after {
  display: block;
}

/* Style the merchant-business__checkmark/indicator */
.merchant-business__container .merchant-business__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
