/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.recipient-information__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.recipient-information__input__tag {
  border: 1px solid #d6d6d6;
  padding: 5px;
}

.recipient-information__input__tag input {
  border: none;
  width: 100%;
  background: white;
  border-radius: 2px;
}

.recipient-information__input__tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.recipient-information__input__tag__tags li {
  list-style: none;
}

.recipient-information__input__tag__tags li.recipient-information__input__tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

.recipient-information__input:active,
.recipient-information__input:focus {
  border: none !important;
  outline: 0;
}

.recipient-information__input::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px;
  color: #8597a0 !important  ;
}

.recipient-information__input__text__area::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px;
  color: #8597a0 !important  ;
}

#form-action {
  .audio-list {
    background: #101317;
    border-radius: 5px;
    height: 100px;
    width: 100px;

    .selector {
      background: #e10177;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
      width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 14px;
    }
    .mike {
      margin-top: 55%;
    }
    .play {
      width: 25px;
      height: 25px;
      margin-left: 13px;
      cursor: pointer;
    }
  }
  .video-list {
    background: #101317;
    border-radius: 5px;
    height: 100px;
    width: 100px;

    .mike {
      margin-top: 75%;
    }
    .play {
      width: 25px;
      height: 25px;
      margin-left: 13px;
      cursor: pointer;
    }
  }
}

.user-name-suggestions-list-box {
  position: relative !important;
  top: -14px !important;
}

.audio-list {
  background: #101317;
  border-radius: 5px;
  height: 100px;
  width: 100px;

  .selector {
    background: #e10177;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
  }
  .mike {
    margin-top: 55%;
  }
  .play {
    width: 25px;
    height: 25px;
    margin-left: 13px;
    cursor: pointer;
  }
}
.video-list {
  background: #101317;
  border-radius: 5px;
  height: 100px;
  width: 100px;

  .mike {
    margin-top: 75%;
  }
  .play {
    width: 25px;
    height: 25px;
    margin-left: 13px;
    cursor: pointer;
  }
}

.audio-close-icon {
  left: 75px;
  position: relative;
  top: 27px;
}

.available-credits {
  font-size: 56px !important;
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 32px !important;
  }
}