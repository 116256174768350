/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
.personal-details__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.upload-file-style {
  border: 1px dashed #4d5d67;
  padding: 50px;
  text-align: center;
}

/* The personal-details-container */
.personal-details-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 7px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.personal-details-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid #e10177;
}

/* On mouse-over, add a grey background color */
.personal-details-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.personal-details-container input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.personal-details-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.personal-details-container .checkmark:after {
  top: 2px !important;
  left: 2px !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background: #e10177;
}

.personal-details-image-name__text__ellipsis {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
