/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.business-details__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.business-details-image-name__text__ellipsis {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.merchant-profile-custom-file-input {
  color: transparent;
}
.merchant-profile-custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.merchant-profile-custom-file-input::before {
  content: 'Upload new';
  color: #e10177;
  display: inline-block;
  width: 150px;
  border: 1px solid #e10177;
  border-radius: 5px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  font-size: 16px;
  font-family: 'sofia-pro-semi-bold' !important;
  text-align: center;
}
.merchant-profile-custom-file-input:hover::before {
  border-color: black;
}
.merchant-profile-custom-file-input:active {
  outline: 0;
}
.merchant-profile-custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
