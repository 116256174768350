.dashboard-search__rectanglebig {
  background-color: #ffffff;
}
.header-search__selected {
  width: 90px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #e10177;
}
.header-search__unselected {
  width: 90px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #dbdcdf;
  background-color: #ffffff;
}
.header-search__modal .modal-content {
  border-radius: 20px;
  box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
  background-color: #ffffff;
}
.header-text__Claim-a-code {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #e10177;
}
.dashboard-search_rectangle {
  padding-left: 15px;
  height: 46px;
  border-radius: 23px;
  background-color: #f1f4f7;
  padding-top: 3px;
}
@media only screen and(min-width:750px) and (max-width: 900px) {
  .header-search__modal .modal-content {
    border-radius: 20px;
    box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
    background-color: #ffffff;
  }
}
@media only screen and(max-width:700px) {
  .dashboard-search_rectangle {
    padding-left: 5px;
    height: 46px;
    border-radius: 23px;
    background-color: #f1f4f7;
  }
}
@media (min-width: 320px) and (max-width: 360px) {
  .dashboard-search_rectangle {
    width: 200px;
  }
}
@media (min-width: 360px) and (max-width: 400px) {
  .dashboard-search_rectangle {
    width: 230px;
  }
}
@media (min-width: 400px) and (max-width: 480px) {
  .dashboard-search_rectangle {
    width: 260px;
  }
}
@media only screen and(min-width:700px) {
  .dashboard-search_rectangletext {
    width: 200px;
  }
}
@media only screen and (min-width: 600px) {
  .header-search__modalcolor {
    border-radius: 23px;
    background-color: #f1f4f7;
    width: 290px;
    height: 46px;
    position: relative;
    left: -10px;
  }
}
@media only screen and (min-width: 400px) {
  .header-search__modalcolor {
    border-radius: 23px;
    background-color: #f1f4f7;
    width: 280px;
    height: 46px;
    position: relative;
    left: -15px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 400px) {
  .header-search__modalcolor {
    border-radius: 23px;
    background-color: #f1f4f7;
    width: 270px;
    height: 46px;
    position: relative;
    left: -10px;
  }
}
@media only screen and(min-width:600px) {
  .dashboard-search_rectangle {
    height: 46px;
    border-radius: 23px;
    background-color: #f1f4f7;
  }
}

.dashboard-icons.dahboard-search_icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.dashboard-search.dashboard-user {
  height: 20px;
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
  border: none;
  background-color: #f1f4f7;
}
.dashboard-notification_button {
  background-color: #ffffff;
}
.dashboard-icons__notificationnew {
  object-fit: contain;
}
.dashboard-notification {
  font-family: SofiaPro;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
  background-color: #ffffff;
}

.header-search__card {
  border-radius: 20px;
  box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  width: 300px;
  position: relative !important;
  left: -16px;
  top: -6px;
}

.header-search__input__style {
  height: 40px !important;
  margin: 0 !important;
  border: 0;
  box-shadow: none;
  background: transparent;
}

.header-search__input__style::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 16px;
}

.header-search__rectanle__active {
  box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
  background: #fff;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.header-profile__popup__card {
  border: 0 !important;
  box-shadow: 0 3px 34px -14px rgba(0, 0, 0, 0.3);
  background: #fff;
  position: absolute !important;
  z-index: 9999;
  right: 102px;
}
.header-button-claimcode {
  border-radius: 5px;
  background-color: #fff2fa;
  position: relative;
}

.header-text__Claim-a-code2 {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.header-text__entercode {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.header-Rectangle {
  height: 60px;
  border-radius: 5px;
  border: solid 1px var#dbdcdf;
}
.header-button__Claim {
  width: 200px;
  height: 60px;
  border-radius: 5px;
  background-color: #e10177;
}
.header-text__claim {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.header-text__Cancel {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4d5d67;
}
.header-button__cancelicongreen {
  position: relative;
  top: -52px;
  left: 310px;
  background-color: rgba(16, 19, 23, 0);
}
.header-background_popup {
  background-color: rgba(16, 19, 23, 0.3);

  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1px);

  border: 1px solid rgba(16, 19, 23, 0.1);
}

.header-text__Uh-oh {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #101317;
}

.header-text__Something-went-wrong {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #484849;
}

.header-buttton__tryagain {
  width: 100px;
  height: 60px;
  border-radius: 5px;
  background-color: #e10177;
}
.header-button__home {
  border-radius: 5px;
  width: 100px;
  height: 60px;
  border: solid 1px #e10177;
}
@media only screen and(min-width:700px) {
  .header-buttton__tryagain {
    width: 200px;
    height: 60px;
  }
  .header-button__home {
    width: 200px;
    height: 60px;
  }
}
.header-text__home {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.header-button__canceliconerror {
  position: relative;
  top: -130px;
  left: 140px;
  background-color: rgba(16, 19, 23, 0);
}
.header-background_popup {
  background-color: rgba(16, 19, 23, 0.3);

  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1px);

  border: 1px solid rgba(16, 19, 23, 0.1);
}
.header-text__Code-claimed-success {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #101317;
}
.header-text__Your-thanks-balance {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #484849;
}
.header-seach__modalinput {
  background-color: rgba(241, 244, 247, 9);
  border-radius: 23px;
}
@media only screen and(min-width:600px) {
  .header-seach__modalinput {
    width: 290px;
    position: relative;
    right: 10px;
  }
}
.header-button__canceliconsuccess {
  position: relative;
  top: -250px;
  left: 240px;
  background-color: rgba(16, 19, 23, 0);
}
.header-background_popup {
  background-color: rgba(16, 19, 23, 0.3);

  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1px);

  border: 1px solid rgba(16, 19, 23, 0.1);
}

@media only screen and(min-width:400px) and(max-width:420px) {
  .header-button__cancelicongreen {
    position: relative;
    top: -50px;
    left: 200px;
  }
  .header-button-claimcode {
    border-radius: 5px;
    background-color: #fff2fa;
    position: relative;
  }
  .header-text__Claim-a-code {
    font-size: 12px;
  }
}
@media only screen and(min-width:360px) and (max-width: 369px) {
  .header-search__placeholder::placeholder {
    font-size: 10px;
  }
  .header-button__cancelicongreen {
    position: relative;
    top: -50px;
    left: 200px;
  }

  .header-search__modal .modal-content {
    border-radius: 20px;
    box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
    background-color: #ffffff;
  }
  .header-text__Claim-a-code {
    font-size: 12px;
  }
}
@media only screen and(min-width:300px) and (max-width: 400px) {
  .header-search__placeholder::placeholder {
    font-size: 10px !important;
  }
  .header-button__cancelicongreen {
    position: relative;
    top: -50px;
    left: 200px;
  }
  .header-text__Claim-a-code {
    font-size: 10px;
  }
}

@media only screen and (min-width: 370px) and (max-width: 400px) {
  .header-text__Claim-a-code {
    font-size: 12px;
  }
  .header-search__placeholder::placeholder {
    font-size: 12px !important;
  }
  .header-button__cancelicongreen {
    position: relative;
    top: -50px;
    left: 200px;
  }

  .header-searched__select {
    width: 360px;
    height: 46px;
    border-radius: 20px;
    box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
    background-color: #ffffff;
  }
}
.switch-to-account-modal .modal-content {
  border: 0 !important;
}

@media only screen and(min-width:280px) and(max-width:300px) {
  .header-search__placeholder::placeholder {
    font-size: 10px;
  }
  .header-button__cancelicongreen {
    position: relative;
    top: -50px;
    left: 200px;
  }
  .header-text__Claim-a-code {
    font-size: 6px;
  }
}
.switch-to-account-modal .modal-content {
  border: 0 !important;
}

.switch-to-account-modal__close__icon {
  position: relative;
  top: -35px;
  right: -4px;
  background: transparent;
}

.header-search__select {
  border-radius: 20px;
  box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
  background-color: #ffffff;
}
.search-text__recent {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.search-modal__line {
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
}
.search-text__recentsearch {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.headerbox-search__result {
  width: 422px;
  border-radius: 5px;
  box-shadow: 6px 2px 15px -3px rgba(133, 151, 160, 0.4);
  background-color: #ffffff;
}
.header-search__result {
  margin: 0 16px;
  position: absolute;
  left: -5px;
  top: 55px;
  max-width: 422px;
  width: 100%;
  box-shadow: 6px 2px 15px -3px rgba(133, 151, 160, 0.4);
  background-color: #ffffff;
  z-index: 1;
  max-height: 550px;
  overflow: auto;
}

@media only screen and(min-width:1300px) {
  .header-search__unselected {
    width: 92px;
  }
}
.header-search__textselected {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.header-search__textunselected {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #111313;
}
.header-search__resultline {
  width: 100%;
  height: 1px;
  background-color: #dbdcdf;
}
.header-search__results-found {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}
.header-search__results-foundusername {
  font-family: SofiaPro;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b5c3cc;
}
.header-search__selectbutton {
  width: 70px;
  height: 40px;
  border-radius: 5px;
  background-color: #e10177;
}
.header-search__Sendtext {
  width: 33px;
  height: 18px;
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.header-search__Ovalimage {
  width: 40px;
  height: 40px;
}
.header-searched__select {
  width: 300px;
  height: 46px;
  border-radius: 20px;
  box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
  background-color: #ffffff;
}
@media only screen and(min-height:1024px) and (max-height: 1079px) {
  .header-search__modal .modal-content {
    border-radius: 20px;
    box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
    background-color: #ffffff;
  }
}

@media only screen and(min-height:1366px) {
  .header-search__modal .modal-content {
    border-radius: 20px;
    box-shadow: 0 2px 8px -3px rgba(133, 151, 160, 0.4);
    background-color: #ffffff;
  }
}

@media only screen and (max-width: 460px) {
  .header-search__result {
    margin: 0;
    max-height: 470px;
  }
}

.header-search__ovalavailablelocation {
  width: 40px;
  height: 40px;
  background-color: #db9cc1;
}
.header-search__Manly-Cafe {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.header-search__result::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.header-search__result::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.header-notifications__popup__card {
  border: 0 !important;
  box-shadow: 0 3px 34px -14px rgba(0, 0, 0, 0.3);
  background: #fff;
  position: fixed !important;
  z-index: 9999;
  right: 0px;
  top: 60px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.header-notifications__popup__card::-webkit-scrollbar {
  width: 0.5em;
}

.header-notifications__popup__card::-webkit-scrollbar-thumb {
  background-color: #eee;
  outline: 1px solid #f1f1f1;
}

.view-text-style {
  text-decoration: underline !important;
  color: #e10177 !important;
}
