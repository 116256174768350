/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
.user-details__div__height {
  height: 100vh !important;
}

.user-details__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.user-details__success_icon {
  position: relative;
  right: -385px;
  top: -34px;
}

.user-details__error_icon {
  position: relative;
  right: -385px;
  top: -34px;
}

@media screen and (max-width: 400px) {
  .user-details__success_icon {
    position: relative;
    right: -237px;
    top: -34px;
  }

  .user-details__error_icon {
    position: relative;
    right: -237px;
    top: -34px;
  }
}
