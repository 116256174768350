/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.mapview-map__main {
  width: '1000px';
  height: '500px';
}

.mapview-text__MapView {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484849;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mapview-style__size {
  height: 470px;
}

@media only screen and(min-height:1024px) {
  .mapview-style__size {
    height: 900px;
  }
}

@media only screen and(min-height:1366px) {
  .mapview-style__size {
    height: 1225px;
  }
}

@media only screen and(min-width:1400px) {
  .mapview-style__size {
    height: 728px;
  }
}
@media only screen and(min-width:1300px) and (max-width: 1400px) {
  .mapview-style__size {
    height: 580px;
  }
}
@media only screen and(min-width:200px) and (max-width: 300px) {
  .mapview-style__size {
    height: 550px;
    width: 240px;
  }
}
@media only screen and(min-width:320px) and (max-width: 359px) {
  .mapview-style__size {
    height: 400px;
    width: 280px;
  }
}

@media only screen and(min-width:360px) and (max-width: 369px) {
  .mapview-style__size {
    height: 400px;
    width: 320px;
  }
}
@media only screen and(min-width:370px) and(max-width:400px) {
  .mapview-style__size {
    height: 400px;
    width: 340px;
  }
}

@media only screen and(min-width:400px) and(max-width:420px) {
  .mapview-style__size {
    height: 520px;
    width: 380px;
  }
}
@media only screen and(min-width:400px) and(max-width:420px) {
  .mapview-style__size {
    height: 400px;
    width: 380px;
  }
}
@media only screen and(min-height:2000px) {
  .mapview-style__size {
    height: 1900px;
    width: 1250px;
  }
}
.mapview-popup__text {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}
.mapview-popup__The-corso-Manly {
  font-family: SofiaPro;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4d5d67;
}
