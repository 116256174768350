/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
@media screen and (max-width: 500px) {
  .profile-menu__list {
    display: flex;
    justify-content: space-between;
  }
  .profile-menu__list li {
    margin: 2px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #eee;
  }
}
