/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.select-item__checked_icon {
  position: absolute;
  top: -10px;
  right: -10px;
}

.select-item__quantity_placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.select-item-wine__info__icon {
  position: relative;
  right: 20px;
}

.select-item-coming__soon__text {
  position: relative;
  top: -120px;
  left: 2px;
  background: #fff;
  padding: 5px;
  font-size: 20px;
  border-radius: 5px;
}

.select-item-modal__close__icon {
  position: relative;
  top: -30px;
  left: 240px;
}

.select-item-modal__banner__image {
  top: -28px;
  position: relative;
  border-radius: 4px;
}

.select-item__error__info {
  position: relative;
  bottom: 1px;
  left: -4px;
}
