.thumbnail {
  width: 240px;
  height: 240px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.image {
  width: 240px;
  height: 240px;
  border-radius: 10px;
  object-fit: contain;
  background-color: #000000;
}

.playIconContainer {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 8px 10px 12px;
  border-radius: 50%;
}

.outer {
  width: 240px;
  height: 240px;
  background-color: #000000;
  border-radius: 10px;
  overflow: hidden;
}
.video {

}