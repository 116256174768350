#audio_modal,
#video_modal {
  .modal-body {
    .timer-count {
      padding: 15px;
      background: rgba(16, 19, 23, 0.57);
      color: white;
      font-size: 1.5rem;
      right: 2%;
      position: absolute;
      z-index: 99999999;
    }
  }
}
