/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

// .login-left__half {
//     background-image: url(../../assets/images/left_panel/merchants.png);
//     background-size: 100%;
//     background-position: center;
//   }
.login-input__append__design {
  padding: 1rem;
}
.login-left__half .rec.rec-arrow {
  visibility: hidden !important;
}

.login-right__half {
  height: 100%;
}

.login-email__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.login-password__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.login-left__horizontal__line {
  position: relative;
  left: -107px;
  top: 30px;
  width: 184px !important;
}

.login-right__horizontal__line {
  position: relative;
  right: -107px;
  top: -27px;
  width: 184px !important;
}

.slick-slide img {
  margin: auto;
}

.login-slick__dots .slick-dots {
  position: absolute !important;
  bottom: 7px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  right: 300px !important;
}

.login-slick__dots .slick-dots li button:before {
  font-size: 12px !important;
  color: #fff !important;
  border: 1px solid #b5c3cc !important;
  border-radius: 10px;
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
}

.login-organisation__image {
  max-width: 100%;
}

.login-merchant__image {
  max-width: 100%;
}

.login-individual__image {
  max-width: 100%;
}

.login-google__btn {
  background-color: transparent !important;
  display: inline-flex !important;
  align-items: center !important;
  color: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
  border-radius: 0 !important;
  border: none !important;
  outline: none !important;
}

.login-google__btn div svg {
  display: none !important;
}

.login-instagram__btn {
  background: transparent !important;
  outline: none;
}

@media screen and (max-width: 400px) {
  .login-slick__dots .slick-dots {
    position: absolute !important;
    bottom: 3px !important;
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    right: -102px !important;
    left: 2px;
    width: 112px !important;
  }
  .login-input__append__design {
    padding: 0.6rem;
  }
  .login-left__horizontal__line {
    position: relative;
    left: -88px;
    top: 30px;
    width: 106px !important;
  }

  .login-right__horizontal__line {
    position: relative;
    right: -98px;
    top: -27px;
    width: 106px !important;
  }
}

@media (min-width: 400px) and (max-width: 481px) {
  .login-input__append__design {
    padding: 0.6rem;
  }
}

@media screen and (max-width: 768px) {
  .login-left__half {
    max-height: 100vh !important;
  }

  .login-slick__dots .slick-dots {
    position: absolute !important;
    bottom: 10px !important;
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    right: -102px !important;
    left: 16px;
    width: 112px !important;
  }

  .login-slick__dots .slick-dots li button:before {
    width: 15px !important;
    height: 15px !important;
  }

  .login-right__horizontal__line {
    position: relative;
    right: -98px;
    top: -27px;
    width: 130px !important;
  }

  .login-left__horizontal__line {
    position: relative;
    left: -96px;
    top: 30px;
    width: 145px !important;
  }
}

@media screen and (min-width: 1920px) {
  .login-right__half {
    height: 100vh;
  }
}

.Toastify__toast-container {
  width: 350px !important;
}

.eye-icon-color {
  color: #b2c4cd !important;
}

.login-password__icon__position {
  position: relative;
  right: 4px;
}

.login-input__append__design {
  border: 1px solid #dbdbe0;
  border-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.login-password__input {
  border-right: 0 !important;
  outline: none;
  box-shadow: unset !important;
}

.login-or__container {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e5e8ea;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.login-or__container span {
  background: #fff;
  padding: 0 10px;
}
