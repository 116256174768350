/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
// .payment-main {
//   flex-direction: column-reverse;
// }
.thanks-success-icon {
  width: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thanks-text-cont {
  width: 70%;
}
@media (min-width: 320px) and (max-width: 360px) {
  .thanks-text-cont {
    width: 100%;
  }
  .w-350 {
    width: 300px !important;
  }
  .font-24 {
    font-size: 16px !important;
  }
  .thansks-success-card {
    padding: 0.5rem !important;
  }
  .w-180 {
    width: 120px !important;
  }
  .h-60 {
    height: 50px !important;
  }
  .payment-main {
    align-items: center;

    flex-direction: column-reverse;
  }
  .thanks-success-icon {
    width: 320px;
    &-image {
      width: 280px;
    }
  }
  .w-150 {
    width: 100px !important;
  }
  .font-16 {
    font-size: 12px !important;
  }
  .font-36 {
    font-size: 28px !important;
  }
  .line-height-42 {
    line-height: 30px !important;
  }
  .font-20 {
    font-size: 16px !important;
  }
}
@media (min-width: 360px) and (max-width: 400px) {
  .thanks-text-cont {
    width: 100%;
  }
  .font-24 {
    font-size: 19px !important;
  }
  .thansks-success-card {
    padding: 0.5rem !important;
  }
  .w-180 {
    width: 130px !important;
  }
  .h-60 {
    height: 45px !important;
  }
  .w-150 {
    width: 125px !important;
  }
  .payment-main {
    align-items: center;

    flex-direction: column-reverse;
  }
  .thanks-success-icon {
    width: 360px;

    &-image {
      width: 320px;
    }
  }
  .font-36 {
    font-size: 28px !important;
  }
  .line-height-42 {
    line-height: 30px !important;
  }
  .font-20 {
    font-size: 16px !important;
  }
}
@media (min-width: 400px) and (max-width: 480px) {
  .thanks-text-cont {
    width: 100%;
  }
  .font-24 {
    font-size: 21px !important;
  }
  .thansks-success-card {
    padding: 0.5rem !important;
  }
  .w-180 {
    width: 150px !important;
  }
  .h-60 {
    height: 50px !important;
  }
  .payment-main {
    flex-direction: column-reverse;
    align-items: center;
  }
  .thanks-success-icon {
    width: 395px;
    &-image {
      width: 340px;
    }
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .thanks-text-cont {
    width: 100%;
  }
  .send-again-div {
    width: 50% !important;
  }
  .home-div {
    width: 50% !important;
  }
  .payment-main {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .thanks-text-cont {
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 992px) {
  .send-again-div {
    width: 50% !important;
  }
  .home-div {
    width: 50% !important;
  }
  .payment-main {
    flex-direction: column-reverse;
    align-items: center;
  }
  .w-150 {
    width: 150px !important;
  }
}
@media (min-width: 992px) and (max-width: 1180px) {
  .w-180 {
    width: 150px !important;
  }
}
