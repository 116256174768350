/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

.store-locations__active {
  height: 10px !important;
  width: 10px !important;
  background-color: #34c253 !important;
  border-radius: 100%;
}

.store-locations__inactive {
  height: 10px !important;
  width: 10px !important;
  background-color: red !important;
  border-radius: 100%;
}

.store-location__container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.store-location__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.store-location__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.store-location__container:hover input ~ .store-location__checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.store-location__container input:checked ~ .store-location__checkmark {
  background-color: #e10177;
}

/* Create the store-location__checkmark/indicator (hidden when not checked) */
.store-location__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the store-location__checkmark when checked */
.store-location__container input:checked ~ .store-location__checkmark:after {
  display: block;
}

/* Style the store-location__checkmark/indicator */
.store-location__container .store-location__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.merchant-store__location__modal .modal-content {
  border: 0 !important;
}

.merchant-store-modal__close__icon {
  position: relative;
  top: -45px;
  left: 17px;
  background: transparent;
}

.pac-container {
  z-index: 10000 !important;
}

.isRedeemRadioBtn:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -4px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.isRedeemRadioBtn:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -4px;
  position: relative;
  background-color: #e10177;
  content: '';
  display: inline-block;
  visibility: visible;
  padding: 2px;
}

.isPublicRadioBtn:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -4px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.isPublicRadioBtn:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -4px;
  position: relative;
  background-color: #e10177;
  content: '';
  display: inline-block;
  visibility: visible;
  padding: 2px;
}

/* The merchant-location__container */
.merchant-location__container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.merchant-location__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.merchant-location__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.merchant-location__container:hover input ~ .merchant-location__checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.merchant-location__container input:checked ~ .merchant-location__checkmark {
  background-color: #e10177;
}

/* Create the merchant-location__checkmark/indicator (hidden when not checked) */
.merchant-location__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the merchant-location__checkmark when checked */
.merchant-location__container input:checked ~ .merchant-location__checkmark:after {
  display: block;
}

/* Style the merchant-location__checkmark/indicator */
.merchant-location__container .merchant-location__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
