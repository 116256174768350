/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
.mt-2 {
  margin-top: 1.25rem !important;
}
.set-username__div__height {
  height: 100vh !important;
}

.set-username__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.set-username__success_icon,
.set-username__error_icon {
  position: absolute;
  right: 10px;
  top: 20px;
}

.pos-relative {
  position: relative;
}

.username-box {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .username-box {
    width: 75%;
  }

  .suggested-user-names-list {
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .username-box {
    width: 100%;
  }
}
