.redeemnow-text__Step-1-Select-your {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
}
.redeemnow-text__Step-1-Select-your .text-style-1 {
  font-weight: normal;
}

.redeemnow-text__A-long-even-growing {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #101317;
  text-align: justify;
}
.redeemnow-text__Shiraz {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.redeemnow-text__chadony {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.redeemnow-rectangle__small {
  background-color: rgba(225, 1, 119, 0.05);
}
.redeemnow-text__More-information {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
}
.redeemnow-text__Personal-details {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
}
@media only screen and(max-width:500px) {
  .redeemnow-button__submit {
    width: 400px;
  }
}
@media only screen and(max-width:350px) {
  .redeemnow-button__submit {
    width: 300px;
  }
  .redeemnow-image__height {
    height: 100px;
  }
}
@media only screen and(max-width:300px) {
  .redeemnow-button__submit {
    width: 250px;
  }
  .redeemnow-image__height {
    height: 100px;
  }
}

@media only screen and(min-width:500px) {
  .redeemnow-image__Tick {
    object-fit: contain;
    position: relative;
    left: 35px;
    bottom: 40px;
  }
  .redeemnow-image__buttonwine {
    position: relative;
    left: -10px;
  }

  .redeemnow-card__winetype {
    width: 410px;
    height: 126px;
  }
  .redeemnow-rectangle__small {
    width: 460px;
    height: 55px;
  }

  .redeemnow-card__shirazselected {
    width: 410px;
    height: 126px;
  }
  .redeemnow-card__chardonnay {
    width: 410px;
    height: 126px;
  }
  .redeemnow-input {
    width: 460px;
    height: 60px;
  }
  .redeemnow-card__rose {
    width: 410px;
    height: 126px;
  }
  .redeemnow-input__inputfield {
    width: 500px;
  }
  .redeemnow-button__submit {
    width: 550px;
  }
  .redeemnow-button__navigation {
    position: relative;
    left: -15px;
  }
}
.redeemnow-text__fullname {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.redeemnow-input {
  border-radius: 5px;
  border: solid 1px #dbdcdf;
  // width: 500px;
}

.Enter-Email {
  width: 391px;
  height: 30px;
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  color: #9f9f9f;
}
.redeemnow-text__Delivery-details {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
}
input {
  outline: none;
}
.redeemnow-text__enterman {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
  text-wrap: nowrap;
}
.redeemnow-button__entermanualy {
  width: 153px;
  height: 46px;
  border-radius: 5px;
  border: solid 1px #e10177;
  background-color: transparent;
}
.redeemnow-button__submit {
  height: 60px;
  border-radius: 5px;
  background-color: #e10177;
}
.redeemnow-text__submit {
  width: 336px;
  height: 30px;
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.redeemnow-image__tick {
  position: relative;
  right: 135px;
  bottom: 40px;
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.redeemnow-image__wine {
  position: relative;
  left: 24px;
}

.redeemnow-card__chardonnay {
  border-radius: 5px;
  border: solid 1px #dfdb5c;
}
.redeemnow-card__rose {
  border-radius: 5px;
  border: solid 1px #d6a79d;
}

.redeemnow-image__ComponentsWineImageShirazempty {
  width: 141px;
  height: 126px;
  background-color: rgba(240, 240, 240, 1);
}

.pac-container:after {
  content: none !important;
}
.redeemnow-search__search {
  position: static;
}
.redeemnow-input__width {
  width: 350px;
  height: 58px;
}
.redeemnow-image__ComponentsWineImageShiraz {
  width: 141px;
  height: 126px;
}
@media only screen and (min-width: 200px) and (max-width: 500px) {
  .redeemnow-image__ComponentsWineImageShiraz {
    width: 125px;
    height: 126px;
  }
}
@media only screen and(min-width:400px) and(max-width:480px) {
  .redeemnow-input__width {
    width: 265px;
  }
  .redeemnow-input {
    width: 300px;
  }
  .redeemnow-image__height {
    height: 100px;
  }
}
@media only screen and(min-width:359px) and (max-width: 369px) {
  .redeemnow-input__width {
    width: 260px;
  }
  .redeemnow-input {
    width: 300px;
  }
  .redeemnow-image__height {
    height: 100px;
  }
}
@media only screen and(min-width:320px) and (max-width: 359px) {
  .redeemnow-input__width {
    width: 210px;
  }
  .redeemnow-input {
    width: 250px;
  }
  .redeemnow-image__height {
    height: 100px;
  }
}
@media only screen and(min-width:370px) and(max-width:400px) {
  .redeemnow-input__width {
    width: 250px;
  }
  .redeemnow-input {
    width: 280px;
  }
  .redeemnow-image__height {
    height: 100px;
  }
}
