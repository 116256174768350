.organisation-dashboard__My-organisation {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8597a0;
}
.organisationdashboard-home {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #e10177;
  max-width: 120px;
}
