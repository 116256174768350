/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.reset-password-email__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

@media screen and (max-width: 768px) {
  .reset-password-left__half {
    max-height: 90vh !important;
  }
}

@media screen and (min-width: 1920px) {
  .reset-password-right__half {
    height: 100vh;
  }
}

.Toastify__toast-container {
  width: 350px !important;
}

.reset-password__input {
  border-right: 0 !important;
  outline: none;
  box-shadow: unset !important;
}

@media screen and (max-width: 500px) {
  .reset-password-left__half {
    height: 100% !important;
  }

  .reset-password-right__half {
    height: 100% !important;
  }
}
