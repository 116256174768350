.organisation-topup__Top-up-account {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484849;
}
.organisation-topup__Enter-amount {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.organisation-topup__input {
  border-radius: 3px;
  border: solid 1px #dbdcdf;
  height: 60px;
}
.organisation-topup__Available-credits {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.organisation-topup__amount {
  font-family: SofiaPro;
  font-size: 56px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 32px !important;
  }
}
