/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

.merchant-transactions-filter__btn button:hover {
  background-color: transparent !important;
}

.merchant-transactions__tour__modal .modal-content {
  border: 0 !important;
  border-radius: 40px !important;
}

.merchant-transactions__selected__menu {
  padding: 2px;
  width: 5px;
  height: 20px;
  background: #e10177;
  position: relative;
  left: -12px;
}

.merchant-transactions__selected__menu__label {
  position: relative;
  left: -6px;
}

.transaction-search__icon {
  background: #fff;
  display: flex;
  align-items: center;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.transaction-search__input {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}
