/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
@media (min-width: 576px) {
  #audio_modal {
    max-width: 700px !important;
  }
}

@media (max-width: 500px) {
  #audio_modal {
    max-width: 700px !important;
  }

  #audio_modal {
    top: 30px !important;
  }
}

#audio_modal {
  .modal-body {
    height: 400px;
    background: #000;
    border-radius: 5px;

    .btn-model-close {
      position: absolute;
      right: 0px;
      top: -27px;
      width: 24px;
      height: 24px;
      object-fit: contain;
    }

    .analyzer-canvas {
      width: 100%;
      height: 200px;
      position: absolute;
      left: 0;
      top: 28%;
    }

    .middle-line {
      border: 0.1px solid #fff;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
    }

    .audio-list {
      position: absolute;
      background: #101317;
      border-radius: 5px;
      height: 100px;
      width: 100px;
      right: 15px;

      .close {
        position: absolute;
        left: -10px;
        top: -15px;
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
      .selector {
        background: #e10177;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 14px;
        margin: unset;
      }
      .mike {
        margin-top: 55%;
      }
      .play {
        width: 25px;
        height: 25px;
        margin-left: 13px;
        cursor: pointer;
      }
    }
  }
  .modal-footer {
    flex: 1;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;

    .rec-toggle-btn {
      position: absolute;

      .rect-inactive-btn {
        border-top: 1px solid #e9d9e5;
      }

      .rec-active-btn {
        background-color: #ffe1f4 !important;
      }
    }

    .modal-center-action {
      flex: 1;
      display: flex;
      justify-content: center;

      .record-button-mike {
        height: 40px;
        width: 40px;
        background-image: linear-gradient(to bottom, #f203af, #fa0a70);
        border-radius: 100px !important;
        padding: 0px !important;
      }
      .record-button-mike-disabled {
        height: 40px;
        width: 40px;
        background: #b5c3cc;
        border-radius: 100px !important;
        padding: 0px !important;
      }

      .record-button-stop {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}
