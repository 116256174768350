/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
.personal-recipient-main{
  background-color: #f8f9fa;

}
.recipient-botton-cont {
  background-color: #f8f9fa;
}
.main-card {
  display: flex;
}
.hide-on-big {
  display: none;
}
.remaining-chracters {
  font-size: 16px;
  text-align: end;
}
.message-types-cont {
  padding-left: 5rem;
  margin-left: 5rem;
}
.recipient-record-video {
  height: 30px;
  width: 34px;
}
.message-types-main {
  width: 380px;
}
.recipient-information__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.recipient-information__input__tag {
  border: 1px solid #d6d6d6;
  padding: 5px;
}

.recipient-information__input__tag.error {
  border: 1px solid #e10177;
  padding: 5px;
}

.recipient-information__input__tag input {
  border: none;
  width: 100%;
  background: white;
  border-radius: 2px;
}

.recipient-information__input__tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.recipient-information__input__tag__tags li {
  list-style: none;
}

.recipient-information__input__tag__tags li.recipient-information__input__tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

.recipient-information__input:active,
.recipient-information__input:focus {
  border: none !important;
  outline: 0;
}

.recipient-information__input::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px;
  color: #8597a0 !important  ;
}

.recipient-information__input__text__area::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px;
  color: #8597a0 !important  ;
}

#form-action {
  .audio-list {
    background: #101317;
    border-radius: 5px;
    height: 100px;
    width: 100px;

    .selector {
      background: #e10177;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
      width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 14px;
    }
    .mike {
      margin-top: 55%;
    }
    .play {
      width: 25px;
      height: 25px;
      margin-left: 13px;
      cursor: pointer;
    }
  }
  .video-list {
    background: #101317;
    border-radius: 5px;
    height: 100px;
    width: 100px;

    .mike {
      margin-top: 75%;
    }
    .play {
      width: 25px;
      height: 25px;
      margin-left: 13px;
      cursor: pointer;
    }
  }
}

.user-name-suggestions-list-box {
  position: relative !important;
  top: -14px !important;
}

.audio-list {
  background: #101317;
  border-radius: 5px;
  height: 100px;
  width: 100px;

  .selector {
    background: #e10177;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
  }
  .mike {
    margin-top: 55%;
  }
  .play {
    width: 25px;
    height: 25px;
    margin-left: 13px;
    cursor: pointer;
  }
}
.video-list {
  background: #101317;
  border-radius: 5px;
  height: 100px;
  width: 100px;

  .mike {
    margin-top: 75%;
  }
  .play {
    width: 25px;
    height: 25px;
    margin-left: 13px;
    cursor: pointer;
  }
}

.audio-close-icon {
  left: 75px;
  position: relative;
  top: 27px;
}

.send__thanks-upload-style > input {
  display: none;
}

.send__thanks-upload-style img {
  width: 80px;
  cursor: pointer;
}

.send__thanks-upload-style {
  border: 2px dashed #b5c3cc;
  height: 200px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-btn-align .message-icon {
  height: 40px;
  width: 40px;
}

.message-btn-align span {
  display: block;
}

.message-btn-align {
  display: flex !important;
}

@media screen and (max-width: 400px) {
  .message-btn-align {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .message-btn-align .message-icon {
    height: auto;
    width: auto;
  }
  .message-types-main {
    width: 300px;
  }
  .recipient-record-video {
    height: 26px;
    width: 30px;
  }
  .message-types-cont {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0;
  }
  .hide-on-big {
    display: block;
  }
  .hide-on-mobile {
    display: none;
  }
  .main-card {
    display: grid;
    // width: 95% !important;
    &-div {
      // width: 95% !important;
    }
  }
}

.message-btn-align {
  width: auto;
  display: flex;
}
@media (min-width: 400px) and (max-width: 480px) {
  .w-120 {
    width: 150px !important;
  }
  .message-types-main {
    width: 360px;
  }
  .message-types-cont {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0;
  }
  .hide-on-big {
    display: block;
  }
  .hide-on-mobile {
    display: none;
  }
  .main-card {
    display: grid;
    width: 95% !important;
    &-div {
      width: 100% !important;
    }
  }
}
@media (min-width: 480px) and (max-width: 567px) {
  .w-120 {
    width: 170px !important;
  }
  .message-types-cont {
    padding-left: 0;
    margin-left: 0;
  }
  .hide-on-big {
    display: block;
  }
  .hide-on-mobile {
    display: none;
  }
  .main-card {
    display: grid;
  }
}
@media (min-width: 567px) and (max-width: 768px) {
  .w-120 {
    width: 200px !important;
  }
  .message-types-cont {
    padding-left: 0;
    margin-left: 0;
  }
  .hide-on-big {
    display: block;
  }
  .hide-on-mobile {
    display: none;
  }
  .main-card {
    display: grid;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .w-120 {
    width: 150px !important;
  }
  .message-types-cont {
    padding-left: 0;
    margin-left: 0;
  }
}
