/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.redeemwine-card__small {
  border-radius: 5px;
  background-color: rgba(225, 1, 119, 0.05);
}

.redemmwine-text__thanks {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.redeemwine-text__Hey-mate-Thanks-for {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.redeemwine-text__redeemnow {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.redeemwine-button__redeemnow {
  width: 210px;
  height: 60px;
  border-radius: 5px;
  background-color: #e10177;
}
.redeemwine-text__How-people-are-using {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #111313;
}
.redeemwine-text__How-people-are-using .text-style-1 {
  color: #e10177;
}
.redeemwine-text__Appreci-can-be-used {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.redeemwine-text__Our-mission-is-to-en {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #101317;
}
.redeemwine-line {
  height: 1px;
  background-color: #fadaeb;
}
.redeemwine-text__-Appreci-All-r {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #484849;
}
.top-cont-wine{
  margin: 1rem;
}
@media only screen and(min-width:500px) {
  .redeemwine-card__small {
    // width: 550px;
    height: 269px;
  }
}
.redeemwine-background_popup {
  background-color: rgba(16, 19, 23, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(0.1px);
}
@media (min-width: 320px) and (max-width: 480px) {
  .redeemwine-button__redeemnow {
    width: 130px;
    height: 45px;
    border-radius: 5px;
    background-color: #e10177;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .redeemwine-button__redeemnow {
    width: 160px;
    height: 45px;
    border-radius: 5px;
    background-color: #e10177;
  }
}
