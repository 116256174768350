/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
.merchant-payout__table tbody tr:nth-child(2n + 1) td,
.merchant-payout__table tbody tr:nth-child(2n + 1) th {
  background-color: #fffafd;
}
