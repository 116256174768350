/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.dashboard-rectangle-bottom {
  width: 150px;
  padding: 5px;
  border-radius: 20px;
  background-color: #ffffff;
}
.white-header-sidebar{
width: 100%;
height: 100px;
background-color: #fff;
}
.profile__popup-positionnewaccount {
}
.dashboard-menubar_mobile {
width: 90%;
padding: 4%;
}
.dashboard-icons__caretdown {
  width: 24px;
  height: 24px;
  margin: 3px 0 3px 2px;
  object-fit: contain;
}
.dashboard-account_popup {
  width: 248px;
  height: 127px;
  border-radius: 5px;
  box-shadow: 0 8px 35px -12px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  position: relative;
  z-index: 3;
}
.dashboard-text_account {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-text__transactionhistory {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-account_bar {
  width: 210px;
  height: 1px;
  background-color: #f1f4f7;
}

.dashboard-rectangle-bottom_clicked {
  width: 150px;
  height: 40px;
  padding: 5px;
  border-radius: 20px;
  background-color: #fff2fa;
}
.dashboard_oval {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.dashboard_icon {
  width: 30px;
  height: 30px;
}
.dashboard-button__mobilebar {
  display: none;
}
.dashboard-label {
  display: none;
}
#dashboard-toggle {
  display: none;
}
.dashboard-icons.dashboard-navigation__home__active {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.dashboard-home {
  font-family: SofiaPro;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #e10177;
  max-width: 100px;
}
.dashboard-menu_selected {
  width: 5px;
  height: 22px;
  background-color: #e10177;
}
.dashboard-menu__icon {
  position: relative;
  right: 0px;
}

.dashboard-icons.dashboard-menu__sendthanksinactive {
  object-fit: contain;
}
.dashboard-link_button {
  background-color: #ffff;
}
.dashboard-sidebar {
  width: 16%;
}

//mediaquery
@media only screen and(max-width:700px) {
  .dashboard-logo_mobile {
    margin-left: 70px;
    width: 200px;
  }

  #dashboard-toggle:checked + .dashboard-menu {
    display: block;
    z-index: 300;
    height: 1024px;
  }
  .dashboard-button__mobilebar {
    display: block;
    background-color: #ffffff;
  }
  .dashboard-label {
    font-size: 26px;
    display: block;
    border: 0;
    background-color: #ffff;
  }
  .dashboard-menubar_mobile {
    display: relative;
  }
  .dashboard-rectangle-bottom {
    margin: 0;
    margin-top: 10px;
  }
}

.dashboard-sidebar_hieght {
  height: calc(100vh - 145px);
}
@media only screen and(min-width:760px) {
  .dashboar-sidebar_fixed {
    z-index: 1;
    position: fixed;
  }
  .dashboard-sidebar {
    flex-direction: column !important;
  }
}
// @media only screen and(min-width:992px) and (max-width: 1100px) {
//   .dashboard-sidebar {
//     width: 180px;
//   }
// }
// @media only screen and(min-width:1101px) and (max-width: 1200px) {
//   .dashboard-sidebar {
//     width: 200px;
//   }
// }
// @media only screen and(min-width:1201px) and (max-width: 1300px) {
//   .dashboard-sidebar {
//     width: 215px;
//   }
// }
// @media only screen and(min-width:1301px) and (max-width: 1400px) {
//   .dashboard-sidebar {
//     width: 235px;
//   }
// }

@media only screen and(min-height:1366px) and (max-height: 1400px) {
  .dashboard-home {
    max-width: 100px;
    word-wrap: break-word;
  }
}

// @media only screen and(min-width:1400px) and (max-width: 1600px) {
//   .dashboard-sidebar {
//     width: 264px;
//   }
// }


.dashboard-text__inActive {
  font-family: SofiaPro;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  // color: #b5c3cc;
}
@media only screen and (min-width: 1100px) {
  .dashboard-text__inActive {
    font-size: 20px;
  }
 
}
.modal-backdrop {
  background-color: transparent !important;
  border: 0;
}
.dashboard-modal_popup {
  width: 300px;
}

.merchant-down-caret {
  position: relative;
  right: -63px;
}
.dashboard-sidebar__modal {
  .modal-dialog {
    width: 39px;
    top: 55%;
    overflow: 'hidden';
    left: -45%;
  }
  .modal-content {
    border: 0 !important;
  }
}

.merchant-down-caret {
  position: relative;
  right: -63px;
}

.merchant-account-popup {
  background: transparent !important;
}

.merchant-account-popup .modal-content {
  position: fixed;
  right: 0;
  bottom: 64px;
  left: 30px;
  z-index: 1030;
  width: 210px;
  box-shadow: 0 8px 35px -12px rgba(0, 0, 0, 0.25);
  background: #fff !important;
  border: 0 !important;
}

@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .dashboard-menu_selected {
    position: relative;
    // right: 40px;
    top: 5px;
  }
  .dashboard-sidebar {
    width: 17%;
  }
}

@media only screen and(max-width:768px ) {
  .dashboard-menu_selected {
    position: relative;
    right: 0px;
  }
}

@media only screen and(max-width:768px ) {
  .dashboard-sidebar__modal {
    .modal-dialog {
      width: 39px;
      top: 63%;
      overflow: 'hidden';
      left: -45%;
    }
  }
  .dashboard-menu_selected {
    position: relative;
    right: 18px;
  }
}

@media only screen and(max-width:800px ) {
  .dashboard-sidebar__modal {
    .modal-dialog {
      width: 39px;
      top: 65%;
      overflow: 'hidden';
      left: -45%;
    }
  }

  .dashboard-menu_selected {
    position: relative;
    right: 17px;
  }
}

@media only screen and(min-width:1100px ) {
  .dashboard-sidebar__modal {
    .modal-dialog {
      width: 39px;
      top: 65%;
      overflow: 'hidden';
      left: -45%;
    }
  }

  .dashboard-menu_selected {
    position: relative;
    right: 0;
  }
}

@media only screen and(min-width:1500px ) {
  .dashboard-menu_selected {
    position: relative;
    right: -16px;
  }
}
@media (min-width: 1400px)  and (max-width:1500px){
  .dashboard-menu_selected {
    right: -7px;
    position: relative;

  }
}
@media (min-width: 1600px)  and (max-width:1900px){
  .dashboard-menu_selected {
    right: -19px;
    position: relative;

  }
}
.merchant__dashboard-store-icon {
  margin-top: 8px !important;
}

@media only screen and(min-width:320px) and(max-width:359px) {
  .dashboard-home {
    max-width: 100px;
    word-wrap: break-word;
  }
  .merchant-account-popup .modal-content {
    position: relative;
    top: 130px;
    left: 0px;
  }
}
@media only screen and(min-width:280px) and(max-width:319px) {
  .merchant-account-popup .modal-content {
    position: relative;
    top: 235px;
    left: 0px;
  }

  .dashboard-menu_selected {
    position: relative;
    right: 0;
  }
}
@media only screen and(min-width:360px) and(max-width:369px) {
  .dashboard-home {
    max-width: 100px;
    word-wrap: break-word;
  }
  .merchant-account-popup .modal-content {
    position: relative;
    top: 230px;
    left: 0px;
  }
}
@media only screen and(min-width:370px) and(max-width:400px) {
  .dashboard-home {
    max-width: 100px;
    word-wrap: break-word;
  }
  .merchant-account-popup .modal-content {
    position: relative;
    top: 235px;
    left: 0px;
  }
}
@media only screen and(min-width:400px) and(max-width:420px) {
  .dashboard-home {
    max-width: 100px;
    word-wrap: break-word;
  }
  .merchant-account-popup .modal-content {
    position: relative;
    top: 235px;
    left: 0px;
  }
}
@media only screen and(min-width:770px) and(max-width: 820px) {
  .merchant-account-popup .modal-content {
    position: fixed;
    right: 0;
    bottom: 250px;
    left: 30px;
  }
  .profile__popup-positionnewaccount {
    position: relative;
    bottom: 180px;
  }
}

.merchant__store-dropdown-caret {
  position: relative;
  bottom: 3px;
}

.merchant-onboarding-error__message {
  width: 200px !important;
}

/* ---Sidebar css */
.text-primary {
  color: #5a8dee !important;
}
.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar {
  border-radius: 5px;
}
.sidebar {
  width: 280px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgba(90, 116, 148, 0.3);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 5;
  transition: 0.5s;
}
.sidebar.active {
  left: 0;
}
.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}
.sd-body {
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}
.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}
.sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}
@media (min-width: 1100px)  and (max-width:1200px){
  .dashboard-text__inActive {
    font-size: 18px;
  }
  .dashboard-sidebar {
    width: 17%;
  }
}
@media (min-width:320px) and (max-width:480px){
 .white-header-sidebar{

height: 50px;

}
}