/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.dashboard-text_dot {
  font-size: 50px;
  line-height: 1px;
}
.redeem-yesredeem__cancel {
  position: relative;
  top: -113px;
  left: 450px;
  width: 35px;
}
.redeem-quantity__cancel {
  position: relative;
  top: -48px;
  left: 230px;
}
.dashboard-text__availablelocation {
  font-family: SofiaPro;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-card__currentlocation {
  border-radius: 5px;
  border: solid 1px #b5c3cc;
  height: 50px;
}
.dashboard-redeem__Ovaldeactive {
  width: 8px;
  height: 8px;
  background-color: #e10177;
}
.dashboard-text__yourcurrentlocation {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.dashboard-text-style-1 {
  color: #e10177;
  max-width: 10px;
  word-wrap: break-word;
}
.dashboard-search {
  height: 50px;
  border-radius: 25px;
  background-color: #f8f9fa;
}
.dashboard-search_text {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.dashboard-oval__availablelocation {
  width: 80px;
  height: 80px;
  background-color: #db9cc1;
}
.dashboard-oval__availablelocationimage {
  width: 80px;
  height: 80px;
}
.dashboard-teaxt_manlyguild {
  font-family: SofiaPro;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-text_thecorsomanly20 {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.dashboard-text__kmawayGetDi {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.dashboard-text__kmawayGetDitextstyle-1 {
  font-weight: normal;
  color: #e10177;
}
.dashboard-button_select {
  height: 60px;
  border-radius: 5px;
  background-color: #e10177;
}
.dashboard-text_select {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.dashboard-line {
  height: 1px;
  background-color: #f1f4f7;
}
.dashboard-text_Manly-Guild-Cafe {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
}
.dashboard-popup_heading {
  border-radius: 5px;
  background-color: #fff2fa;
}
.dashboard-model {
  z-index: 100px;
}
.redeem-skip {
  height: 100px;
  width: 100px;
}
.redeem-skip-modal {
  height: 100px;
  width: 100px;
}
.dashboard-text__Are-you-at-the-count {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-text__In-order-to-redeem-y {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #484849;
}
.dashboard-teaxt__In-order-to-redeem-ytext-style-1 {
  color: #e10177;
}
.dashboard-button_cancel {
  background-color: #ffffff;
  position: relative;
  top: 10px;
}
.dashboard-text__yesredeem {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.dashboard-button__yesredeem {
  width: 200px;
  height: 60px;
  margin: 20px 48px 0 0;
  padding: 15px 39px;
  border-radius: 5px;
  background-color: #e10177;
}
.dashboard-button_cancelicon {
  position: relative;
  top: -50px;
  left: 24px;
  background-color: transparent;
}

.dashboard-text__enjoyyour {
  position: relatve;
  top: 50px;
  font-size: 20px;
}
.Path-2-Copy-3 {
  width: 44px;
  height: 24px;
  margin: 0 0 16px 35px;
  border: solid 2px var(--background);
}
.dashboard-text__Click-here-to-report {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  top: 9px;
  left: 8px;
  color: #ffffff;
}
.dashboard-card__clickhere {
  width: 224px;
  height: 115px;
  border-radius: 5px;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #34c253, #34c253);
  background-color: #34c253;
}

@media only screen and(min-width:400px) and(max-width:420px) {
  cancel {
    position: relative;
    top: -48px;
    left: 170px;
  }
  .redeem-yesredeem__cancel {
    position: relative;
    top: -113px;
    left: 340px;
  }
}
@media only screen and(min-width:360px) and (max-width: 369px) {
  .redeem-quantity__cancel {
    position: relative;
    top: -48px;
    left: 150px;
  }
  .redeem-yesredeem__cancel {
    position: relative;
    top: -113px;
    left: 295px;
  }
}

@media only screen and(min-width:200px) and (max-width: 300px) {
  .redeem-quantity__cancel {
    position: relative;
    top: -48px;
    left: 140px;
  }
  .redeem-yesredeem__cancel {
    position: relative;
    top: -113px;
    left: 280px;
  }
}
@media only screen and(min-width:320px) and (max-width: 359px) {
  .redeem-quantity__cancel {
    position: relative;
    top: -48px;
    left: 148px;
  }

  .dashboard-text__yourcurrentlocation {
    font-size: 12px;
  }

  .dashboard-text__yesredeem {
    font-family: SofiaPro;
    font-size: 15px;
  }
  .redeem-yesredeem__cancel {
    position: relative;
    top: -113px;
    left: 275px;
  }
}
@media only screen and(min-width:370px) and(max-width:400px) {
  .redeem-quantity__cancel {
    position: relative;
    top: -48px;
    left: 160px;
  }
}
@media only screen and(min-width:320px) and(max-width:377px) {
  .dashboard-text__availablelocation {
    font-size: 25px;
  }
  .redeem-skip {
    width: 40px;
    height: 40px;
  }
  .redeem-caff__imageround {
    width: 40px;
    height: 40px;
  }
  .dashboard-teaxt_manlyguild {
    font-size: 16px;
  }
  .dashboard-text_thecorsomanly20 {
    font-size: 11px;
  }
  .dashboard-text_select {
    font-size: 12px;
    padding: 0 5px;
  }
  .dashboard-button_select {
    height: 30px;
  }
}
@media only screen and(min-width:377px) and(max-width:480px) {
  .redeem-skip {
    width: 50px;
    height: 50px;
  }
  .redeem-caff__imageround {
    width: 50px;
    height: 50px;
  }
  .dashboard-teaxt_manlyguild {
    font-size: 17px;
  }
  .dashboard-text_thecorsomanly20 {
    font-size: 12px;
  }
  .dashboard-text_select {
    font-size: 12px;
    padding: 0 5px;
  }
  .dashboard-button_select {
    height: 30px;
  }
}
@media only screen and(min-width:480px) and(max-width:640px) {
  .redeem-skip {
    width: 60px;
    height: 60px;
  }
  .redeem-caff__imageround {
    width: 60px;
    height: 60px;
  }
  .dashboard-teaxt_manlyguild {
    font-size: 18px;
  }
  .dashboard-text_thecorsomanly20 {
    font-size: 13px;
  }
  .dashboard-text_select {
    font-size: 13px;
    padding: 0 6px;
  }
  .dashboard-button_select {
    height: 35px;
  }
}
@media only screen and(min-width:640px) and(max-width:992px) {
  .redeem-skip {
    width: 70px;
    height: 70px;
  }
  .redeem-caff__imageround {
    width: 70px;
    height: 70px;
  }
  .dashboard-teaxt_manlyguild {
    font-size: 20px;
  }
  .dashboard-text_thecorsomanly20 {
    font-size: 14px;
  }
  .dashboard-text_select {
    font-size: 15px;
    padding: 0 12px;
  }
  .dashboard-button_select {
    height: 40px;
  }
}
.modal-close-btn {
  position: absolute;
  right: 0 !important;
  top: -35px !important;
}

@media only screen and(min-width:1340px) {
  .dashboard-button_select {
    width: 200px;
  }
}

.redeem-background_popup {
  background-color: rgba(16, 19, 23, 0.09);

  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  border: 1px solid rgba(16, 19, 23, 0.1);
}
.dashboard-donate-button_cancelicon {
  background-color: rgba(16, 19, 23, 0);
}
@media only screen and(min-width:1536px) {
  .dashboard-button_select {
    width: 200px;
  }
}
.redeem-search__availablelocation {
  width: 185px;
  background-color: #f8f9fa;
  outline: none;
}

.redeem-search__availablelocation {
  width: 185px;
  background-color: #f8f9fa;
  outline: none;
}
.redeem-text__Hows-your-experienc {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.redeem-coffee__modal {
  .modal-content {
    border: 0 !important;
  }
}
.redeem-text__userexp {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e2e2e2;
}
.redeem-image__experieanceicon {
  width: 25px;
  height: 32px;
  object-fit: contain;
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.redeem-caff__imageround {
  border-radius: 50%;
}
.redeem-text__How-many-coffees-wo {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}

.redeem-box__selectedvalue {
  width: 60px;
  height: 60px;
  border-radius: 2px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
}
.redeem-button__proceed {
  width: 200px;
  height: 60px;
  border-radius: 5px;
  background-color: #e10177;
}
.redeem-text__proceed {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.redeem-text__Cancel {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4d5d67;
}
.redeem-modal__yesredeem {
  height: 332px;
}
.redeem-card__manlyguild {
  width: 100%;
  height: 70px;
  border-radius: 5px;
  background-color: #fff2fa;
}
.redeem-image__emptystate {
  width: 238px;
  height: 193px;
}

.copy-redeem-skip {
  display: flex;
  justify-content: center;
  &_text {
    font-size: 25px;
    color: #ffffff;
  }
  &_icon {
    height: 45px;
    width: 45px;
  }
  &_btn {
    display: flex !important;
    align-items: center;
    gap: 10px;
  }
}
