/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
.site-logo-appreci{
  height: 20px;
  width: 20px;
}
.dashboard_left_half {
  width: 25%;
  height: conatainer-floid;
  padding: 24px 0 64px 64px;
  background-color: #ffff;
}
.dashboard-text_inactive {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #b5c3cc;
}

.dahs_board_right__half {
  background-color: #f8f9fa;
}

.dashboard-name__goodmorning {
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484849;
}
.right_half {
  background-color: #f8f9fa;
}
.left_half {
  background-color: #ffffff;
}
.dashboard__availablethanks {
  font-family: SofiaPro;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}
.dashboard__available_thanksvalue {
  font-family: SofiaPro;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard__viewvalue {
  width: 79px;
  height: 18px;
  margin: 2px 127px 0 0;
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
}
.dashboard-redeemed__thankschar {
  text-align: start;

  font-family: SofiaPro;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--purple-brown);
}
.dashboard-components1 {
  text-align: center;
}
.dashboard__uhohnochartsyet {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  text-align: center;
  color: #101317;
}
.gift-img {
  &_50 {
    width: 50px;
  }
  &_100 {
    width: 100px;
  }
}

.dashboard__trysendingthanks {
  font-family: SofiaPro;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  text-align: center;
  color: var(--gunmetal);
}
.dashboard-card_send {
  border: 0;
  background-color: #fff2fa;
  padding: 0.5rem 5rem !important;
}
.dashboard-card_redeem {
  border-radius: 5px;
  border: solid 1px #ffe1f4;
  background-color: #ffff;
  padding: 0.5rem 4rem;
}
.dashboard__sendthanks {
  width: 79px;
  height: 18px;
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.dashboard__lastcard {
  width: 718px;
  height: 280px;
  margin: 24px 24px 0 0;
  border-radius: 5px;
  background-color: #ffffff;
}
.dashboard__recenttransaction {
  font-family: SofiaPro;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}
.dashboard__trysendingyourfir {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  text-align: center;
  color: #101317;
}
.dashboard-text__style1 {
  color: #e10177;
}

.dashboard_topaction {
  height: 167px;
}
.dashboard__topactiontext {
  font-family: SofiaPro;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.dashboard-name {
  width: 89px;
  height: 18px;
  margin: 6px 2px 6px 5px;
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-button:focus {
  outline: none;
}
.Shape {
  border-radius: 40px;
  background-color: #ffff;
}

.dashboard-text_logout {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c44440;
}
.dashboard-chart {
  background-color: #ffffff;
}

.dashboard-transaction_card {
  background-color: #ffffff;
}
.tabClass a {
  color: #e10177;
}

@media only screen and(max-width:700px) {
  dashboard-mobile_chart {
    flex-direction: column;
  }
  .navbar-collapse {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    z-index: 100;
    background-color: #ffff;
  }
  .dashboard-mobile_card {
    margin-bottom: 15px;
  }
  .dashboard-mobile__topactioncard {
    margin-bottom: 65px;
  }

  .dashboard-transaction_mobile {
    justify-content: space-evenly;
  }
}
.dashboard-home_inactive {
  width: 24px;
  height: 24px;
}
.dashboard-text_viewall {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e10177;
}
.dashboard-line__latesttransaction {
  width: 95.7%;
  height: 1px;
  background-color: #f0f0f0;
  position: absolute;
}
.dashboard-oval_transaction {
  width: 20px;
  height: 20px;
}
.dashboard-name_transaction {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-email_transaction {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.dashboard-date_transaction {
  color: #101317;
}
.dashboard-sent_transaction {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dahboard-coffee {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.dashboard-heythanks {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: underline;
  color: #231f20;
}
.dashboard-table_notes {
  display: inline-block;
  width: 70px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.dashboard-table_notes:hover {
  overflow: visible;
  word-break: break-all;
}
.dashboard-line__latesttransactionsecond {
  height: 1px;
  background-color: #f0f0f0;
}
.dashboard-text_daily {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
}
.dashboard-text__unactivedates {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b5c3cc;
}
.dashboard-table_transactionhead {
  color: #231f20;
}
.dashboard-card_hieght {
  height: 167px;
}
.dashboard-chart__sent {
  height: 10px;
  width: 10px;
  background-color: #e76fb5;
}
.dasboard-chart__Senttext {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484849;
}
.dashboard-chart__Recived {
  height: 10px;
  width: 10px;
  background-color: #ffb3e3;
}
.dashboard-chart__Redeemed {
  height: 10px;
  width: 10px;
  background-color: #c8d7ea;
}
.dashboard-button__daily {
  height: 34px;
  width: 94px;
  border-radius: 5px;
  border: solid 1px #b5c3cc;
  background-color: #ffffff;
}
.dashbord-text__Dailydashbord-text__Daily {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8597a0;
}
.dashboard-buttom__graphClicked {
  width: 94px;
  border-radius: 5px;
  border-bottom: 0;
  border: solid 1px #b5c3cc;
  background-color: #ffffff;
  position: absolute;
}
.dashboard-line__graph {
  width: 93px;
  height: 1px;
  background-color: #b5c3cc;
}
.dashboard-button__weakly {
  width: 92px;
  background-color: #f1f4f7;
}
@media only screen and(max-width:768px) {
  .dasboard-chart__Senttext {
    font-size: 12px;
  }
}
@media only screen and(min-width:400px) and(max-width:420px) {
  .dashboard-line__latesttransaction {
    width: 92.8%;
  }
}
@media only screen and(min-width:370px) and(max-width:400px) {
  .dashboard-line__latesttransaction {
    width: 92%;
  }
}
@media only screen and(min-width:360px) and (max-width: 369px) {
  .dashboard-line__latesttransaction {
    width: 91.8%;
  }
}
@media only screen and(min-width:320px) and (max-width: 359px) {
  .dashboard-line__latesttransaction {
    width: 90.7%;
  }
}
@media only screen and(min-width:280px) and (max-width: 300px) {
  .dashboard-line__latesttransaction {
    width: 90.7%;
  }
  .dasboard-chart__Senttext {
    font-size: 10.7px;
  }
}
@media only screen and(min-width:768px) {
  .dashboard-line__latesttransaction {
    width: 95.6%;
  }
}
.dashboard-image__Oval {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
.dashboard-mobile__dashboardData {
  font-size: 12px;
}
.dashboard-mobile__card {
  background-color: #ffffff;
  border-radius: 5px;
  height: 70px;
  width: 100px;
  color: #000;
}
@media (min-width: 768px) and (max-width: 991px) {
  .carousel-icons {
    padding: 0 4px;
  }
}
@media (min-width: 320px) and (max-width: 600px) {
  .top-action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .dashboard-card_send {
    padding: 0.5rem 1.5rem !important;
  }
  .dashboard-card_redeem {
    padding: 0.5rem 1rem ;
  }
}
@media (min-width: 400px) and (max-width: 600px) {
  .dashboard-card_send {
    padding: 0.5rem 3rem !important;
  }
  .dashboard-card_redeem {
    padding: 0.5rem 2rem ;
  }
}
