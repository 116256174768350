.redeem-coffee__modal {
  .modal-content {
    border: 0 !important;
  }
}

.dashboard-model_cofee {
  border-radius: 3px;
  background-color: #34c253;
  padding: 3rem 1rem !important;
}

.dashboard-text__enjoyYour {
  font-size: 20px;
}

.container-modal-coffee {
  gap: 20px;
}

.dashboard-text_manlyguildcofee {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}

.dashboar-text_cofee {
  font-family: SofiaPro;
  font-size: 45px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.dashboard-image_coffee {
  width: 160px;
  height: 210px;
  object-fit: contain;
  font-family: SFPro;
  font-size: 160px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #071a4a;
}

.dashboard-text_September-346 {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c8f7d3;
}

.dashboard-text__Cafe-didnt-honour-y {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.dashboard-card__enjoyYour {
  text-align: center;
  position: relative;
  top: 20px;
}

.dashboard-card__enjoyYourFixed {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.dashboard-transformLeftTop {
  width: 44px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(20deg);
  position: absolute;
  left: 20%;
  bottom: 60px;
}


.dashboard-transformLeftBottom {
  width: 50px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(10deg);
  position: absolute;
  left: 20%;
  bottom: 10px;
}

.dashboard-transformFixedRightTop {
  width: 44px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(-20deg);
  position: absolute;
  left: 70%;
  bottom: 60px;
}

.dashboard-transformFixedRightBottom {
  width: 50px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(-10deg);
  position: absolute;
  left: 70%;
  bottom: 10px;
}

.otp__div__height {
  height: 100vh !important;
}

.otp__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.otp__success_icon {
  position: relative;
  right: -385px;
  top: -34px;
}

.otp__error_icon {
  position: relative;
  right: -385px;
  top: -34px;
}

@media screen and (max-width: 400px) {
  .otp__success_icon {
    position: relative;
    right: -237px;
    top: -34px;
  }

  .otp__error_icon {
    position: relative;
    right: -237px;
    top: -34px;
  }
}

.otp-input__box {
  width: 70px !important;
  height: 70px !important;
}

.otp-input__box:focus,
.otp-input__box:active {
  outline: none !important;
  border: 0 !important;
  box-shadow: 0px 0px 0px 2px #e10177;
}




@media only screen and (max-width: 450px) {
  .dashboard-transformLeftTop {
    left: 15%;
  }
  .dashboard-transformLeftBottom {
    left: 15%;
  }
  .dashboard-transformFixedRightTop {
    left: 75%;
  }
  .dashboard-transformFixedRightBottom {
    left: 75%;
  }
}

@media only screen and (max-width: 450px) {
  .dashboard-transformLeftTop {
    left: 10%;
  }
  .dashboard-transformLeftBottom {
    left: 10%;
  }
  .dashboard-transformFixedRightTop {
    left: 80%;
  }
  .dashboard-transformFixedRightBottom {
    left: 80%;
  }
}

@media only screen and (max-width: 350px) {
  .dashboard-transformLeftTop {
    left: 0%;
  }
  .dashboard-transformLeftBottom {
    left: 0%;
  }
  .dashboard-transformFixedRightTop {
    left: 85%;
  }
  .dashboard-transformFixedRightBottom {
    left: 85%;
  }
}