.redeemedbeer-rectangle__big {
  background-color: #ffffff;
}
.redeemedbeer-text__You-have-successfull {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #101317;
}
.redeemedbeer-text__To-cancel-this-trans {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #484849;
}
.redeemedbeer-button-Home {
  border-radius: 5px;
  border: solid 1px #db9cc1;
}
@media only screen and (min-width: 700px) {
  .redeemedbeer-button__senagain {
    width: 200px;
    height: 60px;
    background-color: #e10177;
    border-radius: 5px;
  }
  .redeemedbeer-button-Home {
    width: 200px;
    height: 60px;
    border-radius: 5px;
    border: solid 1px #db9cc1;
  }
  .redeemedbeer-rectangle__big {
    width: 644px;
  }
}
.redeemedbeer-button__senagain {
  background-color: #e10177;
  border-radius: 5px;
}
.redeemedbeer-text__home {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
