/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.plans-section {
  margin: 10px auto;
  padding: 0 10px;
  display: block;
}

.budget-section {
  input[type=radio] {
    accent-color: #e10177;
    width: 20px;
    height: 20px;
    top: 5px;
    position: relative;
  }
  .input-group-text {
    background-color: unset;
    padding-right: 0;
    border-radius: 4px 0 0 4px !important;
  }
  .form-control.budget-input {
    width: 150px !important;
    max-width: 100%;
    padding-left: 0;
    border-left: 0 !important;
  }
  .headcount {
    font-size: 16px;
    font-weight: 500;
  }
}

.topup-slider {
  .budget-section {
    padding: 0 30px 30px;
  }
}

.plans-container {
  gap: 24px;
  width: calc(100% - 50px);
}

.plan-card {
  padding: 30px;
  white-space: nowrap;
  background-color: #f8f9fa;
  &.active {
    background-color: #ffe1f4;
  }
  .plan-text {
    vertical-align: middle;
  }
}

.scale {
  position: absolute;
  bottom: 30px;
  left: -20px;
  right: -15px;
  display: flex;
  justify-content: space-between;
}

.scale_item {
  font-size: 14px;
}
