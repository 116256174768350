/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.otp__div__height {
  height: 100vh !important;
}

.otp__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

.otp__success_icon {
  position: relative;
  right: -385px;
  top: -34px;
}

.otp__error_icon {
  position: relative;
  right: -385px;
  top: -34px;
}

@media screen and (max-width: 400px) {
  .otp__success_icon {
    position: relative;
    right: -237px;
    top: -34px;
  }

  .otp__error_icon {
    position: relative;
    right: -237px;
    top: -34px;
  }
}

.otp-input__box {
  width: 70px !important;
  height: 70px !important;
}

.otp-input__box:focus,
.otp-input__box:active {
  outline: none !important;
  border: 0 !important;
  box-shadow: 0px 0px 0px 2px #e10177;
}
