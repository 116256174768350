/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.thanks-text-cont {
  width: 70%;
}
.thansks-success-card {
  // padding:0.5rem !important;
  margin: 1.4rem;
}
@media (min-width: 320px) and (max-width: 360px) {
  .font-24 {
    font-size: 16px !important;
  }
  .thansks-success-card {
    padding: 0.5rem !important;
    margin: 0;
  }
  .w-180 {
    width: 120px !important;
  }
  .h-60 {
    height: 50px !important;
  }
}
@media (min-width: 360px) and (max-width: 400px) {
  .font-24 {
    font-size: 19px !important;
  }
  .thansks-success-card {
    padding: 0.5rem !important;
  margin: 0;

  }
  .w-180 {
    width: 130px !important;
  }
  .h-60 {
    height: 50px !important;
  }
}
@media (min-width: 400px) and (max-width: 480px) {
  .font-24 {
    font-size: 21px !important;
  }
  .thansks-success-card {
    padding: 0.5rem !important;
  margin: 0;

  }
  .w-180 {
    width: 150px !important;
  }
  .h-60 {
    height: 50px !important;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .send-again-div {
    width: 50% !important;
  }
  .home-div {
    width: 50% !important;
  }
}
@media (min-width: 992px) and (max-width: 1180px) {
  .w-180 {
    width: 150px !important;
  }
}
