/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
.skip-image {
  height: 140px;
  width: 140px;
  &-icon {
    height: 100%;
    width: 100%;
  }
}
.btn-redeem {
  background-color: #e93393 !important;
  width: 180px;
  border-radius: 49px !important;
  border-color: #e93393 !important;
}
.redeem-title {
  display: flex;
}
@media screen and (max-width: 500px) {
  .profile-menu__list {
    display: flex;
    justify-content: space-between;
  }
  .profile-menu__list li {
    margin: 2px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #eee;
  }
}
.redeem-button__redeem {
  width: 185px;
  height: 48px;
  border-radius: 49px;
  background-color: #e10177;
}
@media (min-width: 320px) and (max-width: 480px) {
  .redeem-title {
    display: grid;
  }
  .font-30 {
    font-size: 22px !important;
  }
  .skip-image {
    height: 120px;
    width: 120px;
    &-icon {
      height: 100%;
      width: 100%;
    }
  }
}
@media (min-width: 320px) and (max-width: 350px) {
  .font-30 {
    font-size: 18px !important;
  }
}
@media (min-width: 350px) and (max-width: 380px) {
  .font-30 {
    font-size: 20px !important;
  }
}
