/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.review-thanks__message__text__ellipsis {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.review-thanks__tooltip {
  width: 200px !important;
}
.edit-button-icon{
  display: none;
}
@media (min-width:320px) and (max-width:480px){
  .edit-message-text{
    display: none;
  }
  .edit-button-icon{
    width: 18px;
    height: 20px;
    display:inherit;
  }.step {
    width: 260px;
  }
  .send-again-div{
    text-align: left;
  }
}