.dashboard-card__redeemthanks {
  background-color: #ffffff;
}
.dashboard-redeem__Ovalstatus {
  width: 8px;
  height: 8px;
  background-color: #44d965;
}
.dashboard-redeem__Ovaldeactive {
  width: 8px;
  height: 8px;
  background-color: #e10177;
}
.dashboard-text_redeem {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #e10177;
}
.dashboard-button_redeem {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #db9cc1;
  background-color: #fff2fa;
}

.dashboard-text__redeeminactive {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #b5c3cc;
}
.dashboard-actions_inactive {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #b5c3cc;
  text-align: center;
}
td {
  border-left: 1px solid #f1f4f7;
  border-top: none !important;
}
.dashboard-text_dash {
  font-family: SofiaPro;
  font-size: 14;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  color: #101317;
}
.dashboard-row_nthchild:nth-child(even) {
  background-color: #fffafd;
}
.dashboard-row {
  background-color: #fff2fa;
}
.redeem-text__Start-by-sending-an {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.redeem-image__emptystate {
  width: 238px;
  height: 193px;
  object-fit: contain;
}
.redeem-text__view {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
}
.redeem-button_view {
  width: 101px;
  height: 50px;
  border-radius: 5px;
  border: solid 1px #e10177;
  background-color: #ffffff;
}
.redeem-emptystate {
  position: relative;
  top: 100px;
}
.redeemthanks-text__message {
  max-width: 20px;
  word-wrap: break-word;
}
.redeemthanks-button__textvideo {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
  text-decoration: underline;
}

audio::-webkit-media-controls-panel {
  background-color: #ffffff;
  width: 350px;
  border: 0;
}
.reseemthanks-audio__style {
  background-color: #db9cc1;
}
.redeemthanks-cancel__button {
  position: absolute;
  right: 0;
  top: -68px;
}
.redeem-searchcafename {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.redeemthanks-text__Cancel {
  color: #ffff;
  font-size: 16px;
}
.redeemthanks-mobile__redeemscreen {
  border-radius: 5px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
}
.dashboard-button_redeemmobile {
  height: 40px;
  border-radius: 5px;
  border: solid 1px #db9cc1;
  background-color: #fff2fa;
}
@media only screen and(max-width:800px) {
  .redeemthanks-button__textvideo {
    font-size: 12px;
  }
}
@media only screen and(min-width:400px) and(max-width:420px) {
  .redeemthanks-cancel__button {
    position: relative;
    left: 350px;
    top: -65px;
  }
}
@media only screen and(min-width:300px) and(max-width:321px) {
  .redeemthanks-cancel__button {
    position: relative;
    left: 270px;
    top: -65px;
  }
}
@media only screen and(min-width:321px) and(max-width:360px) {
  .redeemthanks-cancel__button {
    position: relative;
    left: 290px;
    top: -65px;
  }
}
@media only screen and(min-width:361px) and(max-width:375px) {
  .redeemthanks-cancel__button {
    position: relative;
    left: 310px;
    top: -65px;
  }
}
