.suspendcoffee-text_suspendcoff {
  font-family: SofiaPro;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}

.suspendcoffee-search {
  height: 50px;
  border-radius: 25px;
  background-color: #f8f9fa;
}
.suspendcoffee-input_searchcaffe {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
  background-color: #f8f9fa;
}
.suspendcoffee-input__searchcaffecolor {
  background-color: #f8f9fa;
}
.suspendcoffe-text__A-suspended-coffee-i {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #484849;
}
.suspendcoffe-text-NEARBY-LOCATIONS {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.suspend-text__Are-you-sure {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.suspend-coffee__Your-coffee-will-be {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #484849;
}
