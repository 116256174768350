/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

// FONTS

@font-face {
  font-family: 'sofia-pro-bold';
  src: local('sofia-pro-bold'), url(./assets/fonts/Sofia-Pro-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'sofia-pro-light';
  src: local('sofia-pro-light'), url(./assets/fonts/Sofia-Pro-Light.otf) format('opentype');
}

@font-face {
  font-family: 'sofia-pro-medium';
  src: local('sofia-pro-medium'), url(./assets/fonts/Sofia-Pro-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'sofia-pro-regular';
  src: local('sofia-pro-regular'), url(./assets/fonts/Sofia-Pro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'sofia-pro-semi-bold';
  src: local('sofia-pro-semi-bold'), url(./assets/fonts/Sofia-Pro-Semi-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'sofia-pro-italic';
  src: local('sofia-pro-italic'), url(./assets/fonts/Sofia-Pro-Italic.otf) format('opentype');
}
@font-face {
  font-family: 'monteserrat bold';
  src: url('./assets/fonts/Monteserrat/Montserrat-Bold.ttf');
}
@font-face {
  font-family: 'monteserrat normal';
  src: url('./assets/fonts/Monteserrat/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'monteserrat medium';
  src: url('./assets/fonts/Monteserrat/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'varela round';
  src: url('./assets/fonts/VarelaRound-Regular.ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.varela-round {
  font-family: 'varela round';
}
.monteserrat-medium {
  font-family: 'monteserrat medium';
}
.monteserrat-bold {
  font-family: 'monteserrat bold';
}
.monteserrat-normal {
  font-family: 'monteserrat normal';
}
.font-sofia-pro-bold {
  font-family: 'sofia-pro-bold' !important;
}

.font-sofia-pro-light {
  font-family: 'sofia-pro-light' !important;
}

.font-sofia-pro-medium {
  font-family: 'sofia-pro-medium' !important;
}

.font-sofia-pro-regular {
  font-family: 'sofia-pro-regular' !important;
}

.font-sofia-pro-semi-bold {
  font-family: 'sofia-pro-semi-bold' !important;
}

.font-sofia-pro-italic {
  font-family: sofia-pro-italic !important;
}

// COLORS

$primary-color: #e10177 !important;
$black-pearl: #101317 !important;
$charcoal: #111313 !important;
$nobel: #9f9f9f !important;
$white: #fff !important;
$grey-blue: #8597a0 !important;
$light-periwinkle: #dbdcdf !important;
$gunmetal: #4d5d67 !important;
$cloudy-blue: #b5c3cc !important;
$nero: #292929 !important;
$alice-blue: #f8f9fa !important;
$charcoal-grey: #484849 !important;
$very-light-pink: #f0f0f0 !important;
$oslo-grey: #7b7f7f !important;
$pale-mavue: rgba(255, 225, 244, 0.5) !important;
$pale-mavue-1: #ffe1f4 !important;
$purple-brown: #231f20 !important;
$pale-grey: #f1f4f7;
$dark-charcoal: #2f2f2f !important;
$faded-pink: #db9cc1 !important;
$lavender-blush: rgba(255, 242, 250, 0.4) !important;
$pale-pink: #ead6e2 !important;
$lavender-blush-1: #fff2fa !important;
$nobel: #9e9e9e !important;
$white-smoke: #f1f1f1 !important;
$thatch-green: #031a44 !important;
$bouquet: #85969f !important;
$approx-charcoal: #494747 !important;
$cool-green-light: rgba(52, 194, 83, 0.1) !important;
$cool-green: #34c253 !important;
$reddish: #c44440 !important;
$platinum: #e7e7e7 !important;
$ford-gray: #979797 !important;
$pink-closest: #fff7fc !important;
$nero: #0a0300 !important;
$light-grey: #6a6a6a;
$light-grey2: #989898;
$grey: #f5f5f5;

//opacity
.opacity {
  opacity: 0.4;
}

.light-grey2 {
  color: $light-grey2;
}
.light-grey {
  color: $light-grey;
}
.color-primary {
  color: $primary-color;
}
.color-black-pearl {
  color: $black-pearl;
}

.color-charcoal {
  color: $charcoal;
}

.color-white {
  color: $white;
}

.color-grey-blue {
  color: $grey-blue;
}

.color-gunmetal {
  color: $gunmetal;
}

.color-nero {
  color: $nero;
}

.color-alice-blue {
  color: $alice-blue;
}

.color-charcoal-grey {
  color: $charcoal-grey;
}

.oslo-grey {
  color: $oslo-grey;
}

.color-purple-brown {
  color: $purple-brown;
}

.color-dark-charcoal {
  color: $dark-charcoal;
}

.color-nobel {
  color: $nobel;
}

.color-thatch-green {
  color: $thatch-green;
}

.color-bouquet {
  color: $bouquet;
}

.color-approx-charcoal {
  color: $approx-charcoal;
}

.color-cool-green {
  color: $cool-green;
}

.color-reddish {
  color: $reddish;
}

.color-nero {
  color: $nero;
}

.color-cloudy-blue {
  color: $cloudy-blue;
}

// BACKGROUND COLORS

.bg-primary-color {
  background-color: $primary-color;
}
.bd-grey-color2 {
  background-color: $grey !important;
}
.bg-alice-blue {
  background-color: $alice-blue;
}

.bg-very-light-pink {
  background-color: $very-light-pink;
}

.bg-pale-mavue {
  background-color: $pale-mavue;
}
.bg-pale-grey {
  background-color: $pale-grey;
}

.bg-pale-mavue-1 {
  background-color: $pale-mavue-1;
}

.bg-white {
  background-color: $white;
}

.bg-lavender-blush {
  background-color: $lavender-blush;
}

.bg-lavender-blush-1 {
  background-color: $lavender-blush-1;
}

.bg-white-smoke {
  background-color: $white-smoke;
}

.bg-cool-green-light {
  background-color: $cool-green-light;
}

.bg-cool-green {
  background-color: $cool-green;
}

.bg-platinum {
  background-color: $platinum;
}

.bg-ford-gray {
  background-color: $ford-gray;
}

.bg-pink-closest {
  background-color: $pink-closest;
}
// BORDER

.border-primary-color {
  border: 1px solid $primary-color;
}

.border-primary-color-2 {
  border: 2px solid $primary-color;
}

.border-light-periwinkle {
  border: 1px solid $light-periwinkle;
}

.border-cloudy-blue {
  border: 1px solid $cloudy-blue;
}

.border-grey-blue {
  border: 1px solid $grey-blue;
}

.border-bottom-grey-blue {
  border-bottom: 1px solid $grey-blue;
}

.border-bottom-pale-grey {
  border-bottom: 1px solid $pale-grey;
}

.border-faded-pink {
  border: 1px solid $faded-pink;
}

.border-pale-pink {
  border: 1px solid $pale-pink;
}

.border-white {
  border: 2px solid #fff !important;
}

.border-light-pink {
  border-top: 1px solid $very-light-pink;
}

.border-color-pearl {
  border: 1px solid $black-pearl;
}

.border-pale-mavue-1 {
  border: 1px solid $pale-mavue-1;
}

.border-bottom-primary-color {
  border-bottom: 1px solid $primary-color;
}
.border-none {
  border: none !important;
}
// BORDER RADIUS

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-11 {
  border-radius: 11px !important;
}

.border-radius-17 {
  border-radius: 17px !important;
}

.border-radius-22 {
  border-radius: 22px !important;
}

.border-radius-24 {
  border-radius: 24px !important;
}
.border-radius-100 {
  border-radius: 100px !important;
}

.border-right-radius-40 {
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.border-right-0 {
  border-right: 0 !important;
}

// FONT SIZE

.font-56 {
  font-size: 56px !important;
}

.font-45 {
  font-size: 45px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-32 {
  font-size: 32px !important;
}
.font-30 {
  font-size: 30px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-10 {
  font-size: 10px !important;
}

//HEIGHT

.h-350 {
  height: 350px !important;
}

.h-100vh {
  height: 100vh !important;
}

.hm-100vh {
  min-height: 100vh !important;
}

.h-170 {
  height: 170px !important;
}

.h-150 {
  height: 150px !important;
}

.h-120 {
  height: 120px !important;
}

.h-min-145 {
  min-height: 145px !important;
}

.h-80 {
  height: 80px !important;
}

.h-60 {
  height: 60px !important;
}

.h-44 {
  height: 44px !important;
}

.h-40 {
  height: 40px !important;
}

.h-48 {
  height: 48px !important;
}

.h-30 {
  height: 30px !important;
}

// WIDTH
.w-350 {
  width: 350px !important;
}

.w-200 {
  width: 200px !important;
}

.w-180 {
  width: 180px !important;
}

.w-150 {
  width: 150px !important;
}

.w-120 {
  width: 120px !important;
}
.w-140 {
  width: 140px !important;
}

.w-90 {
  width: 90px !important;
}

.wm-500 {
  max-width: 500px;
}

// Gap
.gap-20 {
  gap: 20px;
}

// CURSOR
.cursor-pointer {
  cursor: pointer !important;
}

// LINE HEIGHT
.line-hight-24 {
  line-height: 24px !important;
}

.line-height-42 {
  line-height: 42px !important;
}
.line-height-36 {
  line-height: 36px !important;
}
.line-height-30 {
  line-height: 30px !important;
}

// TOASTER
// .appreci-success__message{
//   background-color: #28bd49  !important;
// }

// OUTLINE
.outline-none {
  outline: none !important;
}

// MODAL
.modal-close-icon {
  position: relative;
  top: -48px;
  right: -18px;
}

.modal-background-shadow {
  backdrop-filter: blur(2px);
  background-color: rgba(16, 19, 23, 0.4);
}

// Z-INDEX
.z-index-1 {
  z-index: 1 !important;
}

.z-index-3 {
  z-index: 2 !important;
}

// List Style
.list-style-none {
  list-style: none !important;
}

// Forms
.form-input-placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

//buttons
.back-btn {
  width: 100px;
}

html, body{
  overflow-x: hidden !important;
}