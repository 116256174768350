.Topup-success__card {
  background-color: #ffffff;
}
.Topup-success__You-have-successfull {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #101317;
}
.Topup-success__To-cancel-this-trans-Copy {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #484849;
}

.Topup-success__Topupmore {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.Topup-success__button {
  height: 60px;
  border-radius: 5px;
  background-color: #e10177;
}
.Topup-success__home {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.Topup-success__homebutton {
  height: 60px;
  padding: 15px 39px;
  border-radius: 5px;
  border: solid 1px #e10177;
  background-color: #ffffff;
}
