/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

.merchant-dashboard-filter__btn button:hover {
  background-color: transparent !important;
}

.merchant-export__option:after {
  position: relative;
  right: 5px;
}

.merchant-export__option option {
  border: 0 !important;
  background-color: #fff !important;
  box-shadow: 0 2px 35px -12px rgba(0, 0, 0, 0.28) !important;
}

.merchant-dashboard__tour__modal .modal-content {
  border: 0 !important;
  border-radius: 40px !important;
}

.merchant-success__modal .modal-content {
  border: 0 !important;
  border-radius: 10px;
}

.merchant-dashboard__selected__menu {
  padding: 2px;
  width: 5px;
  height: 20px;
  background: #e10177;
  position: relative;
  left: -12px;
}

.merchant-dashboard__selected__menu__label {
  position: relative;
  left: -6px;
}
