/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.payitforward-card {
  border-radius: 10px;
  box-shadow: 0 8px 45px -12px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}
.payitforward-text__payitforward {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484849;
}
.payitforward-button__select {
  height: 60px;
  border-radius: 5px;
  background-color: #e10177;
}
.payitforward-text__Your-coffee-will-be {
  font-family: SofiaPro;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #101317;
  text-align: justify;
}
.payitforward-image__suspendcoffe {
  object-fit: contain;
  height: 200px;
}
.payitforward-text__select {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
