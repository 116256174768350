.redeemedwine-card__big {
  border-radius: 5px;
  background-color: rgba(225, 1, 119, 0.05);
}

@media only screen and(min-width:500px) {
  .redeemed-text__We-appreciate-you-ta {
    width: 380px;
    height: 70px;
    margin: 8px 0 0;
  }
  .redeemedwine-card__big {
    width: 550px;
    height: 335px;
  }

  .redeemedwine-card__white {
    width: 550px;
    height: 270px;
  }
  .redeemedwine-text__People-are-using-App {
    width: 380px;
    height: 80px;
    margin: 8px 0 0;
  }
}
.redeemedwine-text__Your-Shiraz-is-on-th {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.redeemed-text__We-appreciate-you-ta {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.redeemedwine-card__white {
  border-radius: 5px;
  box-shadow: 0 4px 40px -12px rgba(40, 39, 39, 0.25);
  background-color: #ffffff;
}
.redeemedwine-text__People-are-using-App {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #101317;
}
.redeemedwine-text__Support-a-cause {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.redeemedwine-text__Support-a-cause .text-style-1 {
  color: #111313;
}
.redeemedwine-Oval__selected {
  width: 12px;
  height: 12px;
  background-color: #e10177;
}
.redeemedwine-Oval__unselected {
  width: 12px;
  height: 12px;
  background-color: rgba(171, 171, 171, 0.3);
}
.redeemedwine-text__Trusted-by {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #111313;
}
