.custom-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .custom-dropdown-icon {
    position: absolute;
    // top: 0;
    right: 0.5rem;
    padding: 10px;
    // background-color: #f0f0f0;
    // border: 1px solid #ccc;
    // cursor: pointer;
  }
  
  select {
    width: 100%;
    padding: 10px;
    appearance: none;
    // border: 1px solid #ccc;
    // background-color: #fff;
    border: none;
    outline: none;
    box-shadow: none;
    @media (max-width: 576px) {
      height: 43px;
    }
    @media (min-width: 320px) and (max-width: 360px) {
      height: 43px;
    }
  }