.addressdetails-rectangle__big {
  background-color: #ffffff;
}
.addressdetails-text__Address_Details {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.addressdetails-text__Find-address {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e10177;
}
@media only screen and (min-width: 700px) {
  .addressdetails-input {
    height: 60px;
    border-radius: 5px;
    border: solid 1px #dbdcdf;
    width: 398px;
  }
}
.addressdetails-input {
  height: 60px;
  border-radius: 5px;
  border: solid 1px #dbdcdf;
}
.addressdetails-label__headertext {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.addresdetails-text__deliveryaddress {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.addresssetails-button__unproceed {
  width: 200px;
  height: 60px;
  background-color: #ffe1f4;
  border-radius: 5px;
}
.addressdetails-button__proceed {
  width: 200px;
  height: 60px;
  background-color: #e10177;
  border-radius: 5px;
}
.personaldetail-text__Enteryourdetails {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484849;
}
.personaldetails-rectangle__big {
  background-color: #ffffff;
}
.personaldetails-text__Personal-details {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.personaldetails-text__headername {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #111313;
}
.personaldetails-input {
  width: 398px;
  height: 60px;
  border-radius: 5px;
  border: solid 1px #dbdcdf;
}
@media only screen and (min-width: 700px) {
  .personaldetails-input {
    height: 60px;
    border-radius: 5px;
    border: solid 1px #dbdcdf;
    width: 640px;
  }
}

@media (min-width: 320px) and (max-width: 360px) {
  .personaldetails-input {
    width: 260px;
  }
}
@media (min-width: 361px) and (max-width: 400px) {
  .personaldetails-input {
    width: 310px;
  }
}
@media (min-width: 401px) and (max-width: 440px) {
  .personaldetails-input {
    width: 330px;
  }
}
@media (min-width: 441px) and (max-width: 480px) {
  .personaldetails-input {
    width: 360px;
  }
}
