.transactionhistory-card__first {
  border-radius: 5px;
  background-color: #ffffff;
}

.transactionhistory-text__Redeem-Transactionsselected {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.transactionhistory-text__Redeem-Transactionsunselected {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #484849;
}
.transactionhistory-bar {
  height: 5px;
  background-color: #e10177;
}
.transactionhistory-card_second {
  background-color: #ffffff;
}
.transactionhistory-table__thead {
  border-radius: 5px;
  background-color: #fff2fa;
}
.transactionhistory-row_nthchild:nth-child(even) {
  background-color: #fffafd;
}

.transactionhistory-row_nthchild:nth-child(odd) {
  background-color: #ffff;
}

.transactionhistory-text__Transaction-ID {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.transactionhistory-text__AP12345 {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.transactionhistory-text__Show-more {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
}
.transactionhistory-text__View {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
  text-decoration: underline;
}
.transactionhistory-button__cancel {
  border-radius: 2px;
  border: solid 1px #e10177;
}

.transactionhistory-text__Cancel {
  font-family: SofiaPro;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
.transactionhistory-text__Yes {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.transactionhistory-button__Yes {
  width: 149px;
  height: 44px;
  border-radius: 5px;
  background-color: #e10177;
}
.transactionhistory-text__No-dont-cancel {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5d67;
}
.dashboard-button__canceliconsend {
  position: relative;
  top: -50px;
  left: 460px;
  background-color: rgba(16, 19, 23, 0);
}
.dashboard-text__Are-you-sure-you-wan {
  font-family: SofiaPro;
  font-size: 19px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #101317;
  position: relative;
  right: 32px;
}
.transactionhistory-image__Oval {
  position: relative;
  top: -70px;
}
.transactionhistory-text__name {
  font-family: SofiaPro;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #101317;
  position: relative;
  top: -60px;
}
.transactionhistory-card__message {
  height: 50vh;
  border-radius: 10px;
  background-color: #f0f0f0;
  position: relative;
  video {
    width: calc(100% - 30px);
    max-height: calc(100% - 30px);
  }
  @media only screen and (max-width: 768px) {
    height: 200px;
    video {
      height: calc(100% - 20px);
    }
  }
}
.transacationhistory-text__message {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #101317;
}
.transactionhistory-text__Send-again {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #e10177;
}

.transactionhistory-button__canceliconsend {
  position: relative;
  top: -50px;
  left: 450px;
}
.transactionhistory-button__seemessage {
  width: 200px;
  height: 60px;
  border-radius: 5px;
  background-color: #ffe1f4;
}
.transactionhistory-text__See-for-video-messag {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #e10177;
}
.transactionhistory-modal__style {
  height: 370px;
}
.transactionhistory-button__sendagain {
  position: relative;
  top: -40px;
}
.transactionhistory-button__bottom {
  position: relative;
  top: -30px;
}
@media only screen and(min-width:700px) and (max-width: 800px) {
  .transactionhistory-text__Transaction-ID {
    font-size: 14px;
  }
}
@media only screen and(min-width:200px) and (max-width: 300px) {
  .transactionhistory-text__Transaction-ID {
    font-size: 8px;
  }

  .transactionhistory-button__canceliconsend {
    left: 215px;
  }
  .transactionhistory-text__Redeem-Transactionsselected {
    font-size: 10px;
  }
  .transactionhistory-text__Redeem-Transactionsunselected {
    font-size: 10px;
  }
}
@media only screen and(min-width:400px) and (max-width: 420px) {
  .transactionhistory-text__Transaction-ID {
    font-size: 8px;
  }

  .transactionhistory-button__canceliconsend {
    left: 330px;
  }
  .transactionhistory-text__Redeem-Transactionsselected {
    font-size: 15px;
  }
  .transactionhistory-text__Redeem-Transactionsunselected {
    font-size: 15px;
  }
}
@media only screen and(min-width:360px) and (max-width: 369px) {
  .transactionhistory-text__Transaction-ID {
    font-size: 8px;
  }
  .transactionhistory-button__canceliconsend {
    left: 300px;
  }
  .transactionhistory-text__Redeem-Transactionsselected {
    font-size: 15px;
  }
  .transactionhistory-text__Redeem-Transactionsunselected {
    font-size: 15px;
  }
}
@media only screen and(min-width:320px) and (max-width: 359px) {
  .transactionhistory-text__Transaction-ID {
    font-size: 8px;
  }
  .transactionhistory-button__canceliconsend {
    left: 250px;
  }
  .transactionhistory-text__Redeem-Transactionsselected {
    font-size: 14px;
  }
  .transactionhistory-text__Redeem-Transactionsunselected {
    font-size: 14px;
  }
}
@media only screen and(min-width:370px) and(max-width:400px) {
  .transactionhistory-text__Transaction-ID {
    font-size: 8px;
  }
  .transactionhistory-button__canceliconsend {
    left: 310px;
  }
  .transactionhistory-text__Redeem-Transactionsselected {
    font-size: 15px;
  }
  .transactionhistory-text__Redeem-Transactionsunselected {
    font-size: 15px;
  }
}
.transactionhistory-text__Gift-card {
  font-family: SofiaPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e10177;
  text-decoration: underline;
}
.transactionhistory-background_popup {
  background-color: rgba(16, 19, 23, 0.05);
  backdrop-filter: blur(0.3px);
}

.transactionhistory-background_popupmessage {
  background-color: rgba(16, 19, 23, 0.5);
  backdrop-filter: blur(0.27px);
}
.transactionhistory-background_popupmessage1 {
  background-color: rgba(16, 19, 23, 0.05);
  backdrop-filter: blur(0.27px);
}

.card-transaction {
  width: 340px;
  // height: 220px;
  top: 20px;
  left: 15px;
}