.anonymous-text__youhave {
  font-family: SofiaPro;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
}
.main-title-cont{
  justify-content: space-between;

}

@media screen and (min-width: 200px) and (max-width: 500px) {
  .anonymous-text__youhave {
    font-family: SofiaPro;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  display: grid;

  }
}
@media (min-width:320px)and (max-width:676px){
  .main-title-cont{
    justify-content: center;
  
  }
}