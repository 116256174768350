/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.payment__placeholder::placeholder {
  font-family: 'sofia-pro-light' !important;
  font-size: 14px !important;
  color: #9f9f9f !important;
}

/* The merchant-payment__container */
.merchant-payment__container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.merchant-payment__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.merchant-payment__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.merchant-payment__container:hover input ~ .merchant-payment__checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.merchant-payment__container input:checked ~ .merchant-payment__checkmark {
  background-color: #e10177;
}

/* Create the merchant-payment__checkmark/indicator (hidden when not checked) */
.merchant-payment__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the merchant-payment__checkmark when checked */
.merchant-payment__container input:checked ~ .merchant-payment__checkmark:after {
  display: block;
}

/* Style the merchant-payment__checkmark/indicator */
.merchant-payment__container .merchant-payment__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The payment-details-container */
.payment-details-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 7px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.payment-details-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0px;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid #e10177;
}

/* On mouse-over, add a grey background color */
.payment-details-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.payment-details-container input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.payment-details-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.payment-details-container .checkmark:after {
  top: 1px;
  left: 1px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #e10177;
}

.merchant__profile-payment-monthly-input::placeholder {
  font-size: 12px !important;
  padding-left: 3px !important;
}
