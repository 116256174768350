/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

.Auth-slider__carousel .carousel-indicators li {
  font-size: 12px !important;
  color: #fff !important;
  border: 1px solid #b5c3cc !important;
  border-radius: 10px;
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
}

.Auth-slider__carousel .carousel-indicators {
  right: 78% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.login-res__image {
  height: 500px;
  width: auto;
}

@media screen and (min-width: 300px) {
  .Auth-slider__carousel .carousel-indicators {
    position: absolute !important;
    bottom: 3px !important;
    display: inline-flex;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    left: -4px;
    width: 112px !important;
  }

  .login-res__image {
    height: 540px;
    width: 540px;
  }
}

@media screen and (min-width: 700px) {
  .login-left__half {
    max-height: 100vh !important;
  }

  .Auth-slider__carousel .carousel-indicators {
    position: absolute !important;
    bottom: 10px !important;
    display: inline-flex;
    padding: 0 !important;
    margin: 0 !important;
    list-style: none;
    text-align: center;
    right: -102px !important;
    left: 1px;
    width: 112px !important;
  }

  .login-res__image {
    height: 768px;
    width: 768px;
  }
}

@media screen and (min-width: 1000px) {
  .login-res__image {
    height: 1044px;
    width: auto;
  }
}

@media screen and (min-width: 1280px) {
  .login-res__image {
    height: 609px;
    width: auto;
  }
}

@media screen and (min-width: 1300px) {
  .login-res__image {
    height: 768px;
    width: auto;
  }

  .Auth-slider__carousel .carousel-indicators {
    position: absolute !important;
    bottom: 10px !important;
    display: inline-flex;
    padding: 0 !important;
    margin: 0 !important;
    list-style: none;
    text-align: center;
    bottom: 60px !important;
    left: 90px;
    width: 112px !important;
  }
}

@media screen and (min-width: 1366px) {
  .login-res__image {
    height: 1042px;
    width: auto;
  }

  .Auth-slider__carousel .carousel-indicators {
    position: absolute !important;
    bottom: 10px !important;
    display: inline-flex;
    padding: 0 !important;
    margin: 0 !important;
    list-style: none;
    text-align: center;
    bottom: 60px !important;
    left: 90px;
    width: 112px !important;
  }
}

@media screen and (min-width: 1400px) {
  .login-res__image {
    height: 900px;
    width: auto;
  }

  .Auth-slider__carousel .carousel-indicators {
    position: absolute !important;
    bottom: 10px !important;
    display: inline-flex;
    padding: 0 !important;
    margin: 0 !important;
    list-style: none;
    text-align: center;
    bottom: 60px !important;
    left: 90px;
    width: 112px !important;
  }
}

@media screen and (min-width: 1536px) {
  .login-res__image {
    height: 1040px;
    width: auto;
  }
}

@media screen and (min-width: 1920px) {
  .login-right__half {
    height: 100vh;
  }
  .login-res__image {
    height: 1042px;
    width: auto;
  }
}

@media screen and (min-width: 2560px) {
  .login-right__half {
    height: 100vh;
  }
  .login-res__image {
    height: 1440px;
    width: auto;
  }
}
