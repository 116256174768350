.selectproduct-text_Select {
  font-family: SofiaPro;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484849;
}
@media only screen and (min-width: 700px) {
  .selectproduct-image__background {
    height: 150px;
  }
}

.selectproduct-image__background {
  object-fit: contain;
  border-radius: 5px;
  background-color: #f8f9fa;
}
.selectproduct-rectangle__list {
  border-radius: 5px;
  background-color: #ffffff;
}
.selectproduct-text__beername {
  font-family: SofiaPro;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}
.selectproduct-text__beerdiscription {
  font-family: SofiaPro;
  font-size: 14px;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7b7f7f;
}
.selectproduct-button__proceed {
  width: 200px;
  height: 60px;
  background-color: #e10177;
  border-radius: 5px;
}
.unselectproduct-button__proceed {
  width: 200px;
  height: 60px;
  background-color: #ffe1f4;
  border-radius: 5px;
}
.selectproduct-beer__selected {
  border-radius: 5px;
  border: solid 2px #e10177;
}

@media only screen and (min-width: 200px) and (max-width: 300px) {
  .selectproduct-beer__unselected {
    width: 230px;
    height: 230px;
  }
}

.selectproduct-text__proceedunselected {
  font-family: SofiaPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #e10177;
}
