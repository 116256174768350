.OrganisationDashboard-card {
  border-radius: 5px;
  background-color: #ffff;
}
.organisationdashboard__available_thanksvalue {
  font-family: SofiaPro;
  font-size: 46px;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #101317;
}

@media only screen and(min-width:700px) and (max-width: 769px) {
  .organisationdashboard__available_thanksvalue {
    font-size: 25px;
  }
}
