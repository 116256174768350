/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
.user-modal .modal-content {
  border: 0 !important;
  padding: 10px;
}

.user-modal__close__icon {
  position: relative;
  top: -55px;
  right: -27px;
  background: transparent;
}

.user-search__icon {
  background: #fff;
  display: flex;
  align-items: center;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.user-search__input {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}

.user__text__ellipsis {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user__name__text__ellipsis {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organisation__user-tabs {
  border-bottom: 0 !important;
  display: flex;
  justify-content: center;
}

.organisation__user-tabs a {
  height: 56px;
  width: 150px;
  border: 1px solid #db9cc1 !important;
  font-size: 16px !important;
  font-family: 'sofia-pro-light' !important;
  color: #4d5d67 !important;
  text-align: center;
  background-color: transparent !important;
  border-radius: 5px !important;
}

.organisation__user-tabs a.active {
  color: #fff !important;
  text-align: center;
  background-color: #e10177 !important;
  border-radius: 5px !important;
  font-family: 'sofia-pro-light' !important;
}

.organisation__user .tab-content {
  // background-color: #f8f9fa !important;
}

.organisation__user-textarea-input {
  outline: none !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  background-color: #f8f9fa !important;
  font-family: 'sofia-pro-light' !important;
}

.invite__employee-upload-style > input {
  display: none;
}

.invite__employee-upload-style img {
  width: 80px;
  cursor: pointer;
}

.invite__employee-upload-style {
  border: 2px dashed #b5c3cc;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table td {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e7edf2;
}

.table tbody tr:last-child td {
  border-bottom: none;
}

.table_headings {
  font-size: 14px !important;
  font-weight: 500;
  color: #101317;
  line-height: 14px;
}

.invite_paragraph {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.skip-note{
  color: red;
  font-size: 16px;
  font-weight: 800 !important;
  text-align: center;
}

.btn_cancel_invite {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.form-control-wrapper {
  position: relative;
}

.date-icon {
  position: absolute;
  right: 0px; // You can adjust the right value to change the position of the icon horizontally
  transform: translateY(-25%);
  @media (max-width: 576px) {
    transform: translateY(-30%);
  }
  @media (min-width: 320px) and (max-width: 360px) {
    transform: translateY(-38%);
  }
}

.title-input {
  width: 95% !important;
  @media (max-width: 767px) {
    width: 48% !important;
  }
}

.title-label {
  font-size: 14px !important;
  @media (max-width: 767px) {
    font-size: 13px !important;
  }
  @media (max-width: 360px) {
    font-size: 11px !important;
  }
}

.milestone-row {
  position: relative !important;
}

.milestone-title {
  position: absolute !important;
  left: 10% !important;
  @media (max-width: 767px) {
    left: 10% !important;
    font-size: 14px !important;
  }
}

.milestone-date {
  position: absolute !important;
  left: 55% !important;
  @media (max-width: 575px) {
    left: 55% !important;
    font-size: 14px !important;
  }
}

.milestone-edit {
  position: absolute !important;
  left: 95% !important;
  @media (max-width: 485px) {
    left: 94% !important;
  }
}
.modal-dialog {
  @media (min-width: 576px) {
    max-width: 950px !important;
    margin: 1.75rem auto !important;
  }
}

.modal-inner {
  @media (min-width: 767px) and (max-width: 1024px) {
    margin-left: 10.75rem !important;
    margin-right: 10.75rem !important;
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  @media (min-width: 1024px) {
    margin-left: 16.75rem !important;
    margin-right: 16.75rem !important;
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  @media (min-width: 576px) {
    margin-left: 4.75rem !important;
    margin-right: 4.75rem !important;
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
}

.modal-invite-inner {
  @media (min-width: 576px) {
    margin-top: 3rem;
    margin-bottom: 6rem;
    margin-right: 12rem;
    margin-left: 12rem;
  }
}

.nav-link {
  display: block !important;
  padding: 1rem 1rem !important;
}

.nav-link-csv {
  border-left: none !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.nav-link-mail {
  border-right: none !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

// .nav-tabs .nav-link {
//   margin-bottom: -1px;
//   border: 1px solid transparent;
//   border-top-left-radius: 0 !important;
//   border-top-right-radius: 0 !important;
// }

.table-edit {
  margin-bottom: 0.65rem !important;
  @media (min-width: 576px) {
    margin-right: 0.5rem !important;
  }
  @media (min-width: 666px) {
    margin-right: 1.5rem !important;
  }
  @media (min-width: 320px) and (max-width: 665px) {
    margin-right: 0.5rem !important;
  }
}

.table-delete {
  margin-bottom: 0.65rem !important;
}

/* Style the input text */
.ant-picker .ant-picker-input > input {
  /* Add your custom styles here */
  margin-top: 10%;
}

.input-group-milestone {
  @media (min-width: 576px) {
    width: 55% !important;
  }
}

.create-milestone-btn {
  @media (max-width: 767px) {
    font-size: 11px !important;
    width: 133px !important;
  }
  @media (min-width: 300px) and (max-width: 360px) {
    font-size: 12px !important;
    width: 139px !important;
  }
}
@media (max-width: 767px) {
  .milestone-mobile-background {
    background: #f8f9fa;
    width: 410px;
    height: 55rem;
  }
}
.milestone-search-btn {
  @media (max-width: 767px) {
    margin-top: 15px;
  }
}
.milestone-footer-btns {
  margin-top: 100px;
  gap: 15px;
}
.card-milestone {
  width: 88%;
  height: 220px;
  top: 20px;
  left: 10px;
  @media (min-width: 376px) and (max-width: 400px) {
    width: 96%;
    // left: 15px;
  }
  @media (min-width: 401px) and (max-width: 450px) {
    width: 98%;
    left: 15px;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    width: 100%;
  }
}
.milestone-type {
  display: flex;
  flex-direction: row;
}

.milestone-headings {
  height: 20px;
  max-width: 93px;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #4d5d67;
  margin-right: 50px;
  padding-bottom: 50px;
}
.send-milestone-btn {
  font-size: 16px !important;
  white-space: nowrap;
  @media (max-width: 576px) {
    font-size: 12px !important;
    width: 77px !important;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    font-size: 55% !important;
    width: 62px !important;
  }
}

.create-modal {
  // margin-left: 0%;
  margin-top: 180px;
  @media (max-width: 576px) {
    margin-top: 208px;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    margin-top: 208px;
  }
  .modal-content {
    // max-width: 410px;
    border: none !important;
  }

  .modal-dialog {
    width: 26rem !important;

    @media (max-width: 576px) {
      width: 21.5rem !important;
      max-width: 950px !important;
      margin: 1.75rem auto !important;
    }
    @media (min-width: 320px) and (max-width: 360px) {
      width: 21.5rem !important;
      max-width: 950px !important;
      margin: 1.75rem auto !important;
    }
  }
}

.btn-flex-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    justify-content: space-around;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media (max-width: 576px) {
    width: 365px;
  }
  @media (min-width: 361px) and (max-width: 414px) {
    width: 385px;
  }
  @media (min-width: 415px) and (max-width: 576px) {
    width: 430px;
  }
}

.btn-flex-item {
  margin-right: 7px;
  border: 1px solid #db9cc1 !important;
  border-radius: 5px !important;
  color: #4d5d67 !important;
  @media (max-width: 767px) {
    margin-right: 0;
    // border: none !important;
    position: relative;
  }
}

.btn-flex-pink-item {
  margin-right: 7px;
  border: 1px solid #e10177 !important;
  border-radius: 5px !important;
  color: #e10177 !important;

  @media (max-width: 767px) {
    margin-right: 0;
    // border: none !important;
    position: relative;
  }
}

.box-style {
  width: 95%;
  margin-top: 1%;
  background-color: white;
  border-radius: 5px;
  padding: 2rem;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
}

.img-card {
  width: 362px;
  height: 232px;

  @media (max-width: 576px) {
    width: 250px;
    height: 170px;
  }
  @media (min-width: 360px) and (max-width: 414px) {
    width: 150px;
    height: 117px;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    width: 250px;
    height: 117px;
  }
  @media (min-width: 360px) and (max-width: 383px) {
    width: 130px;
    height: 117px;
  }
  @media (min-width: 415px) and (max-width: 576px) {
    width: 165px;
    height: 117px;
  }
  @media (min-width: 450px) and (max-width: 500px) {
    width: 180px;
    height: 117px;
  }
  @media (min-width: 501px) and (max-width: 600px) {
    width: 200px;
    height: 117px;
  }
  @media (min-width: 200px) and (max-width: 359px) {
    width: 100px;
    height: 117px;
  }
}

.category-img {
  border-radius: 5px;
  object-fit: cover;
}

.sub-category-img {
  border-radius: 5px;
  object-fit: contain;
}

.select-item_checked_icon {
  position: absolute;
  top: -10px;
  right: -193px;
}

// .card-flex-item {
//   margin-right: 7px;
// }

.coffee-box-style {
  width: 410px;
  margin-top: 1%;
  background-color: white;
  border-radius: 5px;
  padding: 1.25rem;
  // padding-bottom: 2rem;
  @media (max-width: 576px) {
    width: 350px;
    padding: 1.25rem;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    width: 330px;
    padding: 1.25rem;
  }
}

.form-control-coffee {
  display: block;
  width: 364px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @media (max-width: 576px) {
    width: 300px;
    margin-left: 8%;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 300px;
    margin-left: 8%;
  }
}

.btn-coffee {
  width: 176px;

  @media (max-width: 576px) {
    width: 145px;
    margin-left: 16%;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 145px;
    margin-left: 16%;
  }
}

.btn-back-coffee {
  width: 176px;
  color: #b5c3cc !important;
  border: 1px solid #b5c3cc !important;
  border-radius: 5px !important;
  @media (max-width: 576px) {
    width: 145px;
    margin-left: 16%;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 145px;
    margin-left: 16%;
  }
}

.btn-back-recipient {
  width: 176px;
  color: #b5c3cc !important;
  border: 1px solid #b5c3cc !important;
  border-radius: 5px !important;
  @media (max-width: 576px) {
    width: 145px;
    // margin-left: 16%;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 145px;
    // margin-left: 16%;
  }
}

.img-card-coffee {
  width: 362px;
  height: 232px;
  @media (max-width: 576px) {
    width: 350px;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
    height: 200px;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
    height: 185px;
  }
}

.label-coffee {
  @media (min-width: 320px) and(max-width: 360px) {
    margin-left: 8%;
  }

  @media (max-width: 576px) {
    margin-left: 8%;
  }
}

.box {
  border: 1px solid #dbdcdf;
  border-radius: 5px;
  height: 70px;
  width: 153px;
  margin-top: 10px;
  @media (max-width: 767px) {
    height: 55px;
    width: 145px;
    margin-left: 10px;
  }
}

.select-item_checked {
  position: absolute;
  top: -10px;
  right: -87px;
  @media (max-width: 767px) {
    top: -10px;
    right: -87px;
  }

  @media (min-width: 320px) and (max-width: 360px) {
    top: -10px;
    right: -80px;
  }
}

.msg-box-style {
  border: 1px solid #dbdcdf;
  margin-top: 7rem;
  background-color: white;
  border-radius: 5px;
  padding: 2rem;
  width: 181px;
  height: 185px;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  @media (max-width: 576px) {
    width: 170px;
    height: 174px;
    padding: 2rem;
    margin-top: 5rem;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    width: 170px;
    height: 174px;
    padding: 2rem;
    margin-top: 5rem;
  }
}

.select-msg_checked_icon {
  position: absolute;
  top: -48px;
  right: -208px;
  @media (max-width: 576px) {
    top: -57px;
    right: -195px;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    top: -58px;
    right: -174px;
  }
  @media (min-width: 361px) and (max-width: 385px) {
    top: -58px;
    right: -180px;
  }
}

.record-box {
  border-radius: 5px;
  height: 40px;
  width: 117px;
  margin-top: 10px;
  background: #f8f9fa;
  margin-right: 0.5rem;
  @media (max-width: 767px) {
    height: 35px;
    width: 90px;
  }
}

.txt-box {
  border: 1px solid #e10177;
  border-radius: 5px;
  height: 40px;
  width: 117px;
  margin-top: 10px;
  // margin-left: 2rem;
  @media (max-width: 767px) {
    height: 35px;
    width: 90px;
  }
}

.upload-box {
  border: 1px dashed rgb(181, 195, 204);
  padding: 3.5rem;
}

.record-modal {
  // margin-left: 0%;
  margin-top: 155px;
  @media (max-width: 576px) {
    margin-top: 208px;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    margin-top: 208px;
  }
  .modal-content {
    // max-width: 410px;
    border: none !important;
  }

  .modal-dialog {
    width: 26rem !important;

    @media (max-width: 576px) {
      width: 21.5rem !important;
      max-width: 950px !important;
      margin: 1.75rem auto !important;
    }
    @media (min-width: 320px) and (max-width: 360px) {
      width: 21.5rem !important;
      max-width: 950px !important;
      margin: 1.75rem auto !important;
    }
  }
}

.twoBrand-box-style {
  width: 410px;
  margin-top: 1%;
  background-color: white;
  border-radius: 5px;
  padding: 1.25rem;
  // padding-bottom: 2rem;
  @media (max-width: 576px) {
    width: 350px;
    padding: 1.25rem;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    width: 330px;
    padding: 1.25rem;
  }
}

.twoBrand-back-coffee {
  width: 165px;
  color: #b5c3cc !important;
  border: 1px solid #b5c3cc !important;
  border-radius: 5px !important;
  margin-right: 5px;
  @media (max-width: 576px) {
    width: 145px;
    margin-right: 10px;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 145px;
    margin-right: 10px;
  }
}

.twoBrand-coffee {
  width: 165px;
  margin-left: 5px;
  @media (max-width: 576px) {
    width: 145px;
    // margin-left: 10px;
    // margin-right: 50px;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 145px;
    // margin-left: 10px;
    // margin-right: 64px;
  }
}

.summary-coffee {
  width: 165px;
  margin-left: 5px;
  @media (max-width: 576px) {
    width: 145px;
    margin-left: 10px;
    margin-right: 50px;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 145px;
    margin-left: 10px;
    margin-right: 64px;
  }
}

.left-tab {
  border-top-left-radius: 5px; /* Rounded corners on the top-left */
  border-bottom-left-radius: 5px; /* Rounded corners on the bottom-left */
}

.tab {
  height: 56px;
  width: 88px;
  border: 1px solid #db9cc1 !important;
  font-size: 16px !important;
  font-family: 'sofia-pro-light' !important;
  color: #4d5d67 !important;
  text-align: center !important;
  background-color: transparent !important;
  @media (max-width: 767px) {
    height: 40px;
    width: 74px;
    font-size: 14px !important;
  }
}

.tab-active {
  height: 56px;
  width: 88px;
  color: #fff !important;
  text-align: center !important;
  background-color: #e10177 !important;
  // border-radius: 5px !important;
  font-family: 'sofia-pro-light' !important;
  @media (max-width: 767px) {
    height: 40px;
    width: 74px;
    font-size: 14px !important;
  }
}

.right-tab {
  border-top-right-radius: 5px; /* Rounded corners on the top-right */
  border-bottom-right-radius: 5px; /* Rounded corners on the bottom-right */
}

.left-gift {
  height: 56px;
  width: 175px;
  border: 1px solid #db9cc1 !important;
  font-size: 16px !important;
  font-family: 'sofia-pro-light' !important;
  color: #4d5d67 !important;
  text-align: center !important;
  background-color: transparent !important;
  border-top-left-radius: 5px; /* Rounded corners on the top-left */
  border-bottom-left-radius: 5px; /* Rounded corners on the bottom-left */
  @media (max-width: 767px) {
    height: 40px;
    width: 145px;
    font-size: 14px !important;
  }
}

.left-gift-active {
  height: 56px;
  width: 175px;
  color: #fff !important;
  text-align: center !important;
  background-color: #e10177 !important;
  // border-radius: 5px !important;
  font-family: 'sofia-pro-light' !important;
  border-top-left-radius: 5px; /* Rounded corners on the top-left */
  border-bottom-left-radius: 5px; /* Rounded corners on the bottom-left */
  @media (max-width: 767px) {
    height: 40px;
    width: 145px;
    font-size: 14px !important;
  }
}

.right-gift {
  height: 56px;
  width: 175px;
  border: 1px solid #db9cc1 !important;
  font-size: 16px !important;
  font-family: 'sofia-pro-light' !important;
  color: #4d5d67 !important;
  text-align: center !important;
  background-color: transparent !important;
  border-top-right-radius: 5px; /* Rounded corners on the top-right */
  border-bottom-right-radius: 5px; /* Rounded corners on the bottom-right */
  @media (max-width: 767px) {
    height: 40px;
    width: 145px;
    font-size: 14px !important;
  }
}

.right-gift-active {
  height: 56px;
  width: 175px;
  color: #fff !important;
  text-align: center !important;
  background-color: #e10177 !important;
  // border-radius: 5px !important;
  font-family: 'sofia-pro-light' !important;
  border-top-right-radius: 5px; /* Rounded corners on the top-right */
  border-bottom-right-radius: 5px; /* Rounded corners on the bottom-right */
  @media (max-width: 767px) {
    height: 40px;
    width: 145px;
    font-size: 14px !important;
  }
}

.char-count {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 14px;
  color: #384348;
}

.full-width-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  padding: 10px;
  font-size: 16px;
  overflow: auto;
  outline: none;
}

.txtMsg-box {
  border: 1px dashed rgb(181, 195, 204);
  // padding: 3.5rem;
  height: 140px;
}

.summary-box-style {
  width: 410px;
  margin-top: 1%;
  background-color: white;
  border-radius: 5px;
  padding: 1.25rem;
  // padding-bottom: 2rem;
  @media (max-width: 576px) {
    width: 350px;
    padding: 1.25rem;
    margin-right: 50px
  }
  @media (min-width: 320px) and (max-width: 360px) {
    width: 330px;
    padding: 1.25rem;
    margin-right: 64px;
  }
}

.summary-label-box-style {
  width: 100%;
  background-color: #fff2fa;
  border-radius: 5px;
  padding: 0.5rem;
  // height: 100%;
  @media (max-width: 576px) {
    width: 100%;
    padding: 0.5rem;
    // height: 100%;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    width: 100%;
    padding: 0.5rem;
    height: 100%;
  }
}

.summary-label-box-style1 {
  width: 100%;
  background-color: #fff2fa;
  border-radius: 5px;
  padding: 2rem;
  height: 100%;
  @media (max-width: 576px) {
    width: 100%;
    padding: 2rem;
    height: 100%;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    width: 100%;
    padding: 2rem;
    height: 100%;
  }
}

.preview-btn {
  margin-right: 7px;
  border: 1px solid #db9cc1 !important;
  border-radius: 5px !important;
  background-color: white !important;
  width: 183px;
  height: 40px;
}

.pay-field-box-style {
  width: 100%;
  /* background-color: #FFF2FA; */
  border-radius: 5px;
  padding: 0.5rem;
  height: 60px;
  border: 1px solid #e5e5e5;
  @media (max-width: 576px) {
    width: 100%;
    padding: 0.5rem;
    height: 100%;
  }
  @media (min-width: 320px) and (max-width: 360px) {
    width: 100%;
    padding: 0.5rem;
    height: 100%;
  }
}

.img-item {
  width: 310px;
  height: 220px;
  @media (max-width: 576px) {
    width: 250px;
    height: 180px;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 200px;
    height: 140px;
  }
}
@media (max-width: 767px) {
  .titles-text {
    margin-top: 30px !important;
  }
}

@media (min-width: 768px) {
  .titles-text {
    margin-top: 80px !important;
  }
}

@media (max-width: 767px) {
  .notification-bell {
    // left: 365px;
    position: absolute;
    top: 10px;
    right: 5px;
  }
}

@media (max-width: 767px) {
  .product-title {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .choose-gift-type-text {
    font-size: 18px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 767px) {
  .product-type {
    width: 269px;
    height: 24px;
    //styleName: H4;
    font-family: Sofia Pro;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }
}
@media (max-width: 767px) {

  .btn-flex-item.active,
  .btn-flex-pink-item.active {
    border-bottom: 20px solid red;
  }

  .btn-flex-item.active::after,
  .btn-flex-pink-item.active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
  }

  .tab {
    // width: auto;
    font-size: 14px !important;
    height: 40px;
  }

  .tab-active {
    // width: auto;
    font-size: 14px !important;
    height: 40px;
  }

  .left-gift,
  .left-gift-active,
  .right-gift,
  .right-gift-active {
    // width: auto;
    font-size: 14px !important;
    height: 40px;
  }
}
.center-button {
  text-align: center;
  margin-left: 500px;
}

.quantity-box {
  width: 172px;
  height: 62px;
  border: none;
  border-radius: 15px;
  background-color: #FFE1F4;
  margin-top: 0.5rem;
  margin-right: 0.75rem;
}

.quantity-txt {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
  color:#484849;
}

.quantity-balance-txt {
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  color:#484849;
}

.header-search_result {
  position: absolute;
  top: 92px;
  max-width: 422px;
  width: 95%;
  box-shadow: 6px 2px 15px -3px rgba(133, 151, 160, 0.4);
  background-color: #ffffff;
  z-index: 1;
  max-height: 550px;
  overflow: auto;
}

.close-delete {
  position: absolute;
  top: 29px;
  right: 33px;
}

.terms {
  height: 40px;
  margin-left: 25px;
  // @media (max-width: 576px) {
  //   margin-right: 50px;
  // }
  @media (min-width: 320px) and(max-width: 360px) {
    margin-right: 51px;
  }
}

.recipient-modal {
  // width:'410px', backgroundColor:'white', borderRadius:'5px'
  width: 410px;
  background-color: white;
  border-radius: 5px;
  @media (max-width: 576px) {
    width: 330px !important;
  }

  @media (min-width: 320px) and(max-width: 360px) {
    width: 330px !important;
  }
}

.twobrand-modals {
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.slider-description {
  background-color: white;
  border-radius: 5px;
  width: 320px;
}