/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.loading__div__height {
  height: 100vh !important;
}

.loading-card__width {
  width: 360px;
}

@media screen and (max-width: 400px) {
  .loading-card__width {
    width: 338px;
  }
  .loading-coffee__image {
    height: 37px;
    width: 30px;
  }
}
