/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/

.payment-options-radio__item input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: ' ';
  display: block;
  // background: #e10177;
}

.payment-options-radio__item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.payment-options-radio__item input[type='radio'] {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #e10177;
  background-color: #e10177;
  accent-color: #e10177;
}

.payment-options-radio__item label {
  color: #666;
  font-weight: normal;
}

.payment-option__card {
  border: 1px solid #dbdcdf !important;
  padding: 20px !important;
  height: 60px !important;
  border-radius: 5px !important;
}
.anon-payment-option__card{
  // border: 1px solid #dbdcdf !important;
  margin-top: 6px;
  padding: 14px !important;
  height: 40px !important;
  border-radius: 100px !important;
  background-color: #fff;
  border: none;
}

.payment-option__expiry_element {
  border: 1px solid #dbdcdf !important;
  padding: 20px !important;
  height: 60px !important;
  border-radius: 5px !important;
}

.payment-option__cvv {
  border: 1px solid #dbdcdf !important;
  padding: 20px !important;
  height: 60px !important;
  border-radius: 5px !important;
}
.card-details-main {
  display: flex !important;
}

.payment-modal .modal-content {
  border: 0 !important;
}

.payment-modal__close__icon {
  position: relative;
  top: -45px;
  left: 17px;
  background: transparent;
}

.card-icon {
  width: 28px;
  height: 28px;
}

@media (min-width: 320px) and (max-width: 400px) {
  .font-14 {
    font-size: 12px !important;
  }
  .payment-main-card {
    padding: 0.5rem !important;
  }
  .card-number {
    width: 240px;
  }
}
@media (min-width: 400px) and (max-width: 468px) {
  .card-number {
    width: 290px;
  }
}

@media (min-width: 320px) and (max-width: 350px) {
  .font-14 {
    font-size: 11px !important;
  }
  .payment-main-card {
    padding: 0.2rem !important;
  }
}
@media (min-width: 400px) and (max-width: 420px) {
  .font-14 {
    font-size: 13px !important;
  }
}
// @media (min-width: 420px) and (max-width: 480px) {
//   .font-14 {
//     font-size: 14px !important;
//   }
// }

@media (min-width: 320px) and (max-width: 526px) {
  .card-buttons {
    display: grid;
    justify-content: space-evenly;
  }
  .card-details-main {
    padding: 0 !important;
  }
}

@media (min-width: 320px) and (max-width: 468px) {
  .payment-options-radio__item {
    display: flex;
  }
  .card-number {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-details-main {
    display: grid !important;
  }
  .card-buttons {
    display: flex;
  }
}
