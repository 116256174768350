/******************************************************************************
* Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
*
* This file is part of the Appreci Project.
*
* Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
*
********************************************************************************/
.merchant-profile__icon__position {
  position: relative;
  right: 4px;
}

.password-input__append__design {
  border: 1px solid #dbdbe0;
  border-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.password__input {
  border-right: 0 !important;
  outline: none;
  box-shadow: unset !important;
}
